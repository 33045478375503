/*USED TO HANDLE MS GRAPH API */
import { Injectable }                  from '@angular/core';
import { HttpClient }                  from '@angular/common/http';
import { BehaviorSubject, Observable,timer,interval } from 'rxjs';
import { map,take }                         from 'rxjs/operators';
import { RestService }       from './rest.service';

@Injectable({
    providedIn: 'root'
})

export class GraphMailService {
    sessionData = null;
    email:any   = null;
    constructor(private restService: RestService) {
        this.sessionData = JSON.parse(sessionStorage.getItem('currentUser'));
        this.email       = JSON.parse(sessionStorage.getItem('msg'));
    }

//GET INBOX COUNT OF UNREAD MESSAGES
    getInboxCounts(): Observable<any> {
        return this.restService.postFunction({action :'graph/getfolder', vals:{folder:'Inbox'}}).pipe(map(response =>{
            this.sessionData.access_token = response.access_token;
            sessionStorage.setItem('currentUser',JSON.stringify(this.sessionData));
            return response.data;
        }));
    }

//INITIAL EMAIL FETCH. GET ALL EMAILS FOR ALL FOLDERS
    getMail(): Observable<any> {
        let folders = [{folder : 'Inbox', top: (this.email == null)? 20 : this.email.Inbox.messages.length},
                       {folder : 'SentItems', top: (this.email == null)? 20 : this.email.SentItems.messages.length},
                       {folder : 'DeletedItems', top: (this.email == null)? 20 : this.email.DeletedItems.messages.length},
                      ];
        return this.restService.postFunction({action:'graph/getmail', vals: folders}).pipe(map(response =>{
            //FILTER MESSAGES WHERE ACTIVE USER'S EMAIL ACCOUNT IS LISTED IN TO,FROM,CC,BCC
          //This is the problem for the issue with get more mail.
            sessionStorage.setItem('msg',JSON.stringify(response.data));
        }));
    }

//FETCH MORE MAIL BY FOLDER
    getMoreMail(folder:string,skip_url:string): Observable<any> {
        return this.restService.postFunction({action:'graph/getmoremail', vals:{folder:folder,url:skip_url}}).pipe(map(response =>{
            if(!this.email){
              this.email = JSON.parse(sessionStorage.getItem('msg'));
              const old_msg = this.email[folder].messages;
              const new_msg = response.messages;
              this.email[folder].messages = old_msg.concat(new_msg);
              sessionStorage.setItem('msg',JSON.stringify(this.email));
              return response;
            }else{
              const old_msg = this.email[folder].messages;
              const new_msg = response.messages;
              this.email[folder].messages = old_msg.concat(new_msg);
              sessionStorage.setItem('msg',JSON.stringify(this.email));
              return response;
            }

        }));
    }


    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    }


    validateMessage(params) {
       let emailForm = params;
       let response = [];

      if(emailForm['cc'] == ""){
        emailForm['cc'] = [];
      }

       let dataObj = {action:null, vals: {}};
       const recips = ['to','cc','bcc'];

        Object.keys(emailForm).forEach(key =>{
          const contacts = ['to','cc','bcc'];
            if(key =='template_id') {
                dataObj.vals[key] = (!emailForm[key].length)? 1 : emailForm[key][0].ts_id;
            }
            /*VALIDATE DATA. REQUIREMENTS: TO, FROM, SUBJECT, BODY */
            else if(contacts.indexOf(key) > -1) {
                let emails = [];
                if(key =='to') {
                    if(emailForm[key] == null || emailForm[key].length == 0) {
                        response.push([key]+' does not contain an valid email address.');
                    }
                }
                if(typeof emailForm[key] == 'string') {
                    if(emailForm[key].includes(',')) {
                        emailForm[key].split(',').forEach(em =>{
                            if(this.validateEmail(em.trim())) emails.push(em);
                            else response.push([key]+' contains an invalid email address.');
                        });
                    }
                    else {
                        if(this.validateEmail(emailForm[key].trim())) emails.push(emailForm[key]);
                        else response.push([key]+' contains an invalid email address.');
                    }
                }
                else {

                  emailForm[key].forEach(em =>{
                        if(this.validateEmail(em.trim())) emails.push(em);
                        else response.push([key]+' contains an invalid email address.');
                    });
                }

                dataObj.vals[key] = emails;
            }
            else if(key =='subject' || key =='body') {
                if(emailForm[key] == null || !emailForm[key].length) response.push([key]+' is required.');
                else dataObj.vals[key] = emailForm[key];
            }
            else {
                dataObj.vals[key] = emailForm[key];
            }
        });

        if(!response.length) return dataObj;
        else return response;
    }

    sendBulkMessage(params): Observable<any> {
        return this.restService.postFunction(params).pipe(map(response =>{
            return response;
        }));
    }

}
