import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {PhoneDirective} from './directives/';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {DataTableModule} from 'angular-6-datatable';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TransferService, RestService} from './services/';
import {DragulaModule} from 'ng2-dragula';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {NgxPaginationModule} from 'ngx-pagination';
import {QuillModule} from 'ngx-quill';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/*COMPONENTS */
import {AppComponent} from './app.component';
import {TopnavbarComponent} from './components/topnavbar/topnavbar.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {RecordModalComponent} from './components/record-modal/record-modal.component';
import {InboxComponent} from './components/inbox/inbox.component';
import {MatMenuModule} from '@angular/material';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        NgMultiSelectDropDownModule.forRoot(),
        DataTableModule,
        FilterPipeModule,
        BrowserAnimationsModule,
        DragulaModule.forRoot(),
        UiSwitchModule,
        NgxPaginationModule,
        QuillModule.forRoot(),
        MatMenuModule,
        CKEditorModule,
        AngularFontAwesomeModule,
        FontAwesomeModule
    ],
  declarations: [
    AppComponent,
    TopnavbarComponent,
    NavigationComponent,
    LoginComponent,
    PhoneDirective,
    DashboardComponent,
    RecordModalComponent,
    InboxComponent
  ],
   providers:[NgbActiveModal, TransferService, RestService],
  bootstrap: [AppComponent],
  exports: [
    ReactiveFormsModule,
    PhoneDirective
  ],
  entryComponents: []
})
export class AppModule { }

