import { Injectable } from '@angular/core';
import * as io        from 'socket.io-client';
import { Observable } from 'rxjs';
import * as Rx        from 'rxjs';

const sessionData = JSON.parse(sessionStorage.getItem('currentUser'));
@Injectable()
export class SocketService {

  // Our socket connection
  private socket;

  constructor() { }

  connect(): Rx.Subject<MessageEvent> {
    // If you aren't familiar with environment variables then
    // you can hard code `environment.ws_url` as `http://localhost:5000`
    //this.socket = io('http://localhost:5000');
    this.socket = io('http://192.168.1.69:3000',{query: {token :sessionData.token, company_id:sessionData.company_id}});

    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    let observable = new Observable(observer => {
        this.socket.on('ps-search-job-complete', (data) => {
          observer.next(data);
        })
        return () => {
          this.socket.disconnect();
        }
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
        next: (data: Object) => {
            this.socket.emit('ps-search-job-complete', JSON.stringify(data));
        },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Rx.Subject.create(observer, observable);
  }

}
