import { Injectable }                  from '@angular/core';
import { HttpClient }                  from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map }                         from 'rxjs/operators';
import { AuthenticationService }       from './authentication.service';

@Injectable({
    providedIn: 'root'
})

export class RestService {
    url = 'https://portal-api.integrishield.com';

    constructor(private http: HttpClient,
                private auth: AuthenticationService
    ) {}

    postFunction(params): Observable<any> {
      if(window.location.hostname === 'localhost' || window.location.hostname === 'portal-staging.integrishield.com'){
        this.url = 'https://petrovic-portal-api.integrishield.com';
      }

      var user        = JSON.parse(sessionStorage.getItem('currentUser'));
      var dataObj     = {user_id : user.user_id, clients : user.clients, access_token:user.access_token, data : params.vals, email : user.email, client_email: user.client_email};
      return this.http.post<any>(this.url+'/'+params.action, JSON.stringify(dataObj)).pipe(map(data => {
          if(typeof data['access_token'] != 'undefined') {
              user.access_token = data['access_token'];
              sessionStorage.setItem('currentUser',JSON.stringify(user));
          }
          return data;
      }));
    }

}
