import { Component } 			from '@angular/core';
import { TopnavbarComponent } 	from './components/topnavbar/topnavbar.component';
import { NavigationComponent }	from './components/navigation/navigation.component';
import { LoginComponent }		from './components/login/login.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentUser = sessionStorage.getItem('currentUser');
  
}
