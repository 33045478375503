import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute }                                      from '@angular/router';
import {smoothlyMenu}                                                  from "../../app.helpers";
import { AuthenticationService }                                       from '../../services';
import {NgbModal}                                                      from '@ng-bootstrap/ng-bootstrap';
import sha256                                                          from 'crypto-js/sha256';
import { TablesService, RestService,TransferService }                                  from '../../services';
@Component({
  selector: 'topnavbar',
  templateUrl: './topnavbar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./topnavbar.component.css']
})
export class TopnavbarComponent implements OnInit {
  @ViewChild('content', {static: false}) content: ElementRef;
  constructor(private modalService: NgbModal,
    private tablesService: TablesService,
    private route: ActivatedRoute,
    private router: Router,
    private restService : RestService) { }

  currentUser:any = {};
  passwordForm:any = {current: null, new: null, repeat: null};
  passwordRules:any = {len: false,capital:false,number:false,special:false};
  formResponse = {success : null, msg : null};
  ngOnInit() {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }
    toggleNavigation(): void {
        jQuery("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }

/*CONTACT, SPIDER MODAL TOGGLES. CALLS TO THEIR COMPONENTS */
  toggleModal(content,sz) {
    this.passwordForm  = {current: null, new: null, repeat: null};
    this.formResponse  = {success : null, msg : null};
    this.passwordRules = {len: false,capital:false,number:false,special:false};
    this.modalService.open(content,{windowClass: 'dark-modal',backdrop : 'static', size:(!sz)?'md':sz});
  }

  closeModal() {
    this.modalService.dismissAll();
  }


  checkPassword() {
      Object.keys(this.passwordRules).forEach(rule=>{
        if(rule == 'len') {
          if(this.passwordForm.new.length > 7) this.passwordRules.len = true;
          else this.passwordRules.len = false;
        }
        if(rule =='capital') {
          if(this.passwordForm.new.match(/[A-Z]/g)) this.passwordRules.capital = true;
          else this.passwordRules.capital = false;
        }
        if(rule =='number') {
          if(this.passwordForm.new.match(/[0-9]/g)) this.passwordRules.number = true;
          else this.passwordRules.number = false;
        }
        if(rule =='special') {
          if(this.passwordForm.new.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)) this.passwordRules.special = true;
          else this.passwordRules.special = false;
        }
      });
  }

  submitPasswordForm() {
    this.formResponse = {success : null, msg : null};
    Object.keys(this.passwordRules).forEach(rule=>{
      if(!this.passwordRules[rule]) this.formResponse = {success: false, msg : 'New password does not meet the requirements.'};
    });

    if(this.passwordForm.new != this.passwordForm.repeat) this.formResponse = {success: false, msg : 'Repeat and new password do not match.'};

    if(this.formResponse.success === null) {
      let dataObj = {current: this.passwordForm.current.toString(), new: this.passwordForm.new};
        this.restService.postFunction({action: 'auth/updatePassword', vals: dataObj}).subscribe(response =>{
          if(response.error) this.formResponse = {success: false, msg : response.error};
          else {
            this.formResponse = {success: true, msg : 'Password updated successfully.'};
            setTimeout(()=>{ this.closeModal(); },2000);
          }
       });
    }
  }

  logout() {
     //this.restService.postFunction({action: 'users/pslogout', vals: {}}).subscribe(response =>{
     //   if(response) {
          sessionStorage.clear();
          // this.router.navigate(['portal.integrishield.com']);
          location.href = location.origin
      //  }
     //});
  }


}
