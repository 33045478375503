import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    url = 'https://portal-api.integrishield.com';
    loginToken = null;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
      if(window.location.hostname === 'localhost' || window.location.hostname === 'portal-staging.integrishield.com'){
        this.url = 'https://petrovic-portal-api.integrishield.com';
        console.log(this.url)
      }

        return this.http.post<any>(this.url+'/auth/login', JSON.stringify({ email, password}))
            .pipe(map(data => {
                if(typeof data.error == 'undefined'){
                    /* ADD TOKEN */
                    if (data.success) {
                        sessionStorage.setItem('mailboxes',JSON.stringify({'Inbox' :{messages:[], unread:null, total:null, id:null, displayName:'Inbox', rawMessages:[]},
                                             'SentItems' :{messages:[], unread:null,total:null, id:null, displayName:'Sent Items', rawMessages:[]},
                                             'DeletedItems' :{messages:[], unread:null,total:null, id:null, displayName:'Deleted Items', rawMessages:[]}
                                            }));

                        sessionStorage.setItem('currentUser', JSON.stringify(data));
                        this.currentUserSubject.next(data);
                        location.reload();
                    }
                    return data;
                }
                else {
                    return data;
                }
                    return true;
            }));
    }

    // getGuestSession() {
    //     return this.http.post<any>(this.url+'/users/pslogin',{}).pipe(map(guest => {
    //         return guest;
    //     }));
    // }

    // forgotPassword(email: string) {
    //     return this.http.post<any>(this.url+'/users/pslogin',{}).pipe(map(data => {
    //         return data;
    //     }));
    // }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.clear();
        location.reload();
    }
}
