/*THIS IS USED TO TRANSFER DATA BETWEEN COMPONENTS */
import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';

@Injectable()
export class TransferService {
    private subject = new Subject<any>();
    private update  = new Subject<any>();
    private inbox   = new Subject<any>();

    sendData(data: object) {
        this.subject.next({ record: Object.assign({},data) });
    }

    updateData(data: object) {
        this.update.next({ record: Object.assign({},data) });
    }

    clearMessage() {
        this.subject.next();
    }

    getData(): Observable<any> {
        return this.subject.asObservable();
    }

    getUpdate(): Observable<any> {
        return this.update.asObservable();
    }

    openInbox(data: boolean): Observable<any>  {
      this.inbox.next({open : data});
      if (data) {
        return this.inbox.asObservable();
      }
    }
}
