import {Component, OnInit, ViewChild, ElementRef, DoCheck, Pipe, PipeTransform} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {TablesService, RestService, TransferService, GraphMailService} from '../../services';
import {NgbModal, NgbTabChangeEvent, NgbDate, NgbCalendar, NgbActiveModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as countries_json from '../../models/countries.json';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { faFolderMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})

export class InboxComponent implements OnInit {

  faSync = faSync;
  faCoffee = faCoffee;
  faFolderPlus = faFolderPlus;
  faFolderMinus = faFolderMinus;

  search = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200),
    map(term => term === '' ? []: this.addressBook.emails.filter(v => v.email.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)));
  formatter = (x: {email: string}) => x.email;

	@ViewChild('inboxModal', {static: false}) content: any;
  objectKeys              = Object.keys;
	activeFolder            = 'Inbox';
	modalOpen               = false;
  accessToken             = null;
  userData:any      	   = JSON.parse(sessionStorage.getItem('currentUser'));
  mail:any                = JSON.parse(sessionStorage.getItem('mailboxes'));
  sessionData:any         = JSON.parse(sessionStorage.getItem('currentUser'));
  dropdowns:any           = JSON.parse(sessionStorage.getItem('dropdowns'));
  emailForm:any           = {from:this.sessionData.client_email, to:[], bcc:[], cc:[], subject:null ,body:null};
  emailSendLoad: boolean = false;
  addContactsToEmail: any = {group:null, contactType: null, contacts: []};
  addressBook = <any>{emails: [], raw_data: [], groups: []};
  addressBook_dds: any = {
    groups: {
      singleSelection: true,
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      textField: 'group_name',
      idField: 'id',
      itemsShowLimit: 1
    },
    add_groups: {singleSelection: false, allowSearchFilter: true, textField: 'group_name', idField: 'id', itemsShowLimit: 4},
    add_emails: {singleSelection: false, allowSearchFilter: true, textField: 'email', idField: 'email', itemsShowLimit: 2}
  };
  addressBookForm: any = {
    search_by: '',
    email: null,
    display_email: null,
    group: null,
    groups: [],
    display_group: null,
    country: null,
    display_country: null,
    results: [],
    list: [],
    selected: []
  };
  createContactForm: any = {email: null, groups: [], company_id: this.sessionData.clients[0], success: null, msg: null};
  deleteGroupForm: any = {groups: [], success: null, msg: null};
  createGroupForm: any = {group: null, company_id: this.sessionData.clients[0], success: null, msg: null};
  searchInboxForm: any = {from: null, subject: null, msg: null, from_dt: null, day_dt: null, dt_search: '0', to_dt: null};
  inboxFilter: string = null;
  viewedMsg = null;
  loading = true;
  searchLoading = false;
  moreMsgLoading = false;
  constructor(private modalService: NgbModal,
      private activeM : NgbActiveModal,
      private tablesService: TablesService,
      private restService: RestService,
      private transferService : TransferService,
      private domSanitizer : DomSanitizer,
      private graphService : GraphMailService
  ) {
    library.add(fas);

  }

	ngOnInit() {
      this.buildInbox();
      setInterval(()=>{ this.buildInbox(); },3000);
      this.restService.postFunction({action:'services/getalladdresses',vals:[]}).subscribe(response=>{
        this.buildContacts(response.contacts);
      });

      this.loading = false;
	}
/*FETCHES ALL MESSAGES. THIS IS AN INTERVAL THAT IS RUNNING EVERY COUPLE MINUTES. IT UPDATES THE MESSAGES IN THE SESSION STORAGE. THIS GETS THE MESSAGES FROM STORAGE AND UPDATES THE UI WITH THE MESSAGE CHANGES */
  buildInbox() {
      const msg = JSON.parse(sessionStorage.getItem('msg'));

        Object.keys(msg).forEach(folder =>{
          //IF VIEWED MESSAGE ISSET, BE SURE AND SET IT AGAIN WHEN THE INBOX IS UPDATED
          if(this.viewedMsg != null) {
              msg[folder].messages.forEach(mess =>{
                if(mess.id === this.viewedMsg.id) this.viewedMsg = mess;
              });
          }
          this.mail[folder].messages    = msg[folder].messages;
          this.mail[folder].rawMessages = Object.assign([],msg[folder].messages);
          this.mail[folder].unread      = msg[folder].folder.unreadItemCount;
          this.mail[folder].total       = msg[folder].folder.totalItemCount;
          this.mail[folder].id          = msg[folder].folder.id;
          this.mail[folder]['skip']     = msg[folder].skip;
        });
  }
  refreshInbox(){
    this.graphService.getMail().subscribe(res=>{});
  }
/*@TODO: CLEAN UP SO MANY MODALS */
  openModal(content,target,action) {
    let sz:any = 'lg';
    let bg:any = 'dark-modal';
    this.emailForm = {from:this.sessionData.client_email, to:[], bcc:[], cc:[], subject:null ,body:null};

      if(target =='msg') {
        if(action !='new') {
          if(action =='reply') {
            this.emailForm.subject = 'Re: '+this.viewedMsg.subject;
            this.emailForm.to.push(this.viewedMsg.from.emailAddress.address);
          }
          else this.emailForm.subject = 'Fwd: '+this.viewedMsg.subject;

          let recip = [];
          this.viewedMsg.toRecipients.forEach(to =>{
              recip.push(to.emailAddress.address);
          });

          const pos = this.viewedMsg.body.content.indexOf("<body");
          const html = '<p><br /><br /></p><p>____________________________________________________________________</p><p><b>From: '+this.viewedMsg.from.emailAddress.address+'</b></p><p><p><b>To: '+recip.join(", ")+'</b></p><p><b>Sent: '+this.viewedMsg.sentDateTime+'</b></p><p><b>Subject: '+this.viewedMsg.subject+'</b></p>';
           this.emailForm.body = [this.viewedMsg.body.content.slice(0, pos), html, this.viewedMsg.body.content.slice(pos)].join('');
          bg = false;
        }
        else {
          bg = false;
          this.emailForm = {from:this.sessionData.client_email, to:[], bcc:[], cc:[], subject:null ,body:null};
        }
        this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
      }
      else if(target =='inbox') {
        sz = 'md';
        this.searchInboxForm = {from:null, subject:null,msg:null,from_dt:null,to_dt:null, dt_search:'0'};
        this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
      }
      else if(target =='view') {
        this.modalService.open(content,{size: sz, backdrop : 'static'});
      }
      else if(target =='create') {
          this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
      }
      else if(target =='addContact') {
          this.addContactsToEmail = {group:null,contactType:action,contacts:[]};
          this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
      }
      else {
        if(action =='addcountry') {
          sz = 'md';
          this.addressBookForm.list = Object.assign([],this.addressBook.countries);
          this.addressBookForm.list.forEach((cnt,index) =>{
            let match = this.addressBookForm.results.filter(data => data.country_name === cnt.name);
            if(match.length > 0) this.addressBookForm.list.splice(index,1);
          });
            this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
        }
        else if(action =='addemail') {
          sz = 'md';
          this.addressBookForm.list = Object.assign([],this.addressBook.emails);
          this.addressBookForm.list.forEach((em,index) =>{
            let match = this.addressBookForm.results.filter(data => data.email === em.email); //here
            if(match.length > 0) this.addressBookForm.list.splice(index,1);
          });
            this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
        }
        else if (action =='verify') {
          this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
        }
        else if (action =='create') {
          sz = 'md';
          this.activeM = this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
        }
        else {
          this.resetContactForm();
          this.addressBookForm.search_by = '';
          bg = false;
          this.modalService.open(content,{size: sz, backdrop : 'static', windowClass:bg});
        }
      }

  }

/*FETCH NEXT 20 MESSAGES*/
  loadMoreMessages(folder,skip_url) {
    this.moreMsgLoading = true;
    this.graphService.getMoreMail(folder,skip_url).subscribe(resp =>{
      const cur_msg = this.mail[folder].messages;
      const new_msg = resp.messages;
      this.mail[folder].messages = new_msg.concat(cur_msg);
      this.moreMsgLoading = false;
    });
  }

/* PROCESS CREATING A CONTACT */
  createContact() {
    this.createContactForm.msg = null;
    this.createContactForm.success = null;
    let emailvalid = null;

    emailvalid = this.validateEmail(this.createContactForm.email);

    if(!emailvalid) {
      this.createContactForm.success = false;
      this.createContactForm.msg = 'Please enter a valid email address.';
    }
    else if(this.createContactForm.groups.length == 0) {
      this.createContactForm.success = false;
      this.createContactForm.msg = 'Please select at least one country.';
    }
    else {
      let dataObj = {action:'services/updateaddressbook', vals:{}};
      let vals = {action:'add', method:'email', email:this.createContactForm.email, groups:[], company_id:this.createContactForm.company_id};
      this.createContactForm.groups.forEach(cnt =>{
        vals.groups.push(cnt.id);
      });
      dataObj.vals = vals;

      this.restService.postFunction(dataObj).subscribe(response =>{
        this.createContactForm.success = true;
        this.createContactForm.msg = 'Contact successfully created.';
        this.buildContacts(response.contacts.contacts);
        setTimeout(() => {
          this.createContactForm = {email:null,groups:[],company_id:this.sessionData.clients[0], success:null, msg:null};
          this.activeM.close();
          },2000);
      });

    }
  }

  createGroup() {
    this.createGroupForm.msg = null;
    this.createGroupForm.success = null;

    let dataObj = {action:'services/addGroup', vals:{}};
    let vals = {group: this.createGroupForm.group, company_id: this.createGroupForm.company_id};
    dataObj.vals = vals;

    this.restService.postFunction(dataObj).subscribe(response =>{
      this.createGroupForm.success = true;
      this.createGroupForm.msg = 'Group successfully created.';
      this.buildContacts(response.contacts.contacts);
      setTimeout(() => {
        this.createGroupForm = {group:null, company_id:this.sessionData.clients[0], success:null, msg:null};
        this.activeM.close();
      },2000);
    });

  }

  deleteGroups(){
    let dataObj = {action:'services/deleteGroups', vals:{}};
    let vals = {groups:[]};
    this.deleteGroupForm.groups.forEach(cnt =>{
      vals.groups.push(cnt.id);
    });
    dataObj.vals = vals;

    this.restService.postFunction(dataObj).subscribe(response =>{
      this.deleteGroupForm.success = true;
      this.deleteGroupForm.msg = 'Groups successfully deleted.';
      // this.buildContacts(response.contacts.contacts);
      setTimeout(() => {
        this.deleteGroupForm = {groups:[], success:null, msg:null};
        this.activeM.close();
      },2000);
    });
  }
/*THE CONTACT STUFF IS THE SAME AS THE DASHBOARD. ALL EMAIL STUFF SHOULD PROBABLY BE MOVED TO A SERVICE SINCE IT IS SHARED */
  getContacts(action) {
    this.addressBookForm.results = [];
    this.addContactsToEmail.contacts = [];

    if(action =='email') {
      this.addressBookForm.results = this.addressBook.raw_data.filter(data => data.email.toLowerCase().indexOf(this.addressBookForm.email.email.toLowerCase()) > -1);
      this.addressBookForm.email = this.addressBookForm.email.email;
      this.addressBookForm.display_email = this.addressBookForm.email;
      this.addressBookForm.company_id = this.sessionData.clients[0];
    }
    else if(action =='group') {
      this.addressBookForm.results = this.addressBook.raw_data.filter(data => data.group_name.toLowerCase().indexOf(this.addressBookForm.groups[0].group_name.toLowerCase()) > -1);
      this.addressBookForm.display_group = this.addressBookForm.groups[0].group_name;
      this.addressBookForm.company_id = this.sessionData.clients[0];
    }
    else {

      let emails = this.addressBook.raw_data.filter(data => data.group_name.toLowerCase().indexOf(this.addContactsToEmail.group[0].group_name.toLowerCase()) > -1);
      emails.forEach(em =>{
        const match = this.addContactsToEmail.contacts.filter(data => data == em.email);
        if(!match.length)
          this.addContactsToEmail.contacts.push(em.email);
      });
    }
  }

  addContacts() {
    const type = this.addContactsToEmail.contactType;
    let emails = [];
    this.emailForm[type] = [];


    this.addContactsToEmail.contacts.forEach(c =>{
      let match = emails.filter(em => em == c);
      if(!match.length) emails.push(c);
    });
    this.emailForm[type] = emails;
    this.addContactsToEmail = {group:null,contactType:null,contacts:[]};
    this.activeM.close();



  }

  getContactGroups(event) {
    let data = {};
    data['company'] = this.createContactForm.company_id;

    var dataObj = {action : 'services/getGroups', vals: data};
    this.restService.postFunction(dataObj).subscribe(response =>{
      this.addressBook['groups'] = response.data;
    });

  }

/*UPDATE CONTACT VALUES */
  updateContacts(action,id,method) {
    let dataObj = {action:'services/updateaddressbook', vals:{}};

    if(action) {
      //BY COUNTRY
      if(method =='group') {
        let record = {emails: this.addressBookForm.selected, company_id: this.addressBookForm.company_id, group_id: this.addressBookForm.groups[0].id, action:'add', method: method};
        dataObj.vals = record;
      }
      //BY EMAIL
      else {
        let record = {email: this.addressBookForm.email, company_id: this.addressBookForm.company_id, countries: [],action:'add', method: method};
        this.addressBookForm.selected.forEach(cnt => {
          record.countries.push(cnt.country_id);
        });
        dataObj.vals = record;
      }

      this.restService.postFunction(dataObj).subscribe(response =>{
        this.buildContacts(response.contacts.contacts);
        response.new_ids.forEach(id =>{
          const newRecord = response.contacts.contacts.filter(data => data.address_book_id === id);
          this.addressBookForm.results.push(newRecord[0]);
        });
        this.activeM.close();
      });
    }
    else {
      if(id) dataObj.vals = {addressBook_id:id, action:'delete'};
      else dataObj.vals   = {email: this.addressBookForm.email, action:'deleteContact'};


      this.restService.postFunction(dataObj).subscribe(response =>{
          this.buildContacts(response.contacts.contacts);
          if(dataObj.vals['action'] =='deleteContact') {
            this.activeM.close();
            this.resetContactForm();
          }
      });

    }
  }

/*THIS FUNCTION HANDLES MARKING MESSAGES AS READ/UNREAD, FLAGGED/UNFLAGGED*/
  msgAction(action,val) {

      let session_msg = JSON.parse(sessionStorage.getItem('msg'));

      session_msg[this.activeFolder].messages.forEach(msg =>{
          if(msg.id === this.viewedMsg.id) {
              if(action =='isRead') msg[action] = val;
              else msg.flag.flagStatus = val;

              this.viewedMsg = msg;
          }
      });

       this.mail[this.activeFolder].messages = session_msg[this.activeFolder].messages;
       sessionStorage.setItem('msg', JSON.stringify(session_msg));

    let dataObj = {action:'graph/updatemessage', vals:{field:action,value:val, message_id:this.viewedMsg.id}};
    this.restService.postFunction(dataObj).subscribe(response =>{});
  }

/*RESET CONTACT FORM VALUES */
  resetContactForm() {
    this.addressBookForm.email = null;
    this.addressBookForm.display_email = null;
    this.addressBookForm.country = null;
    this.addressBookForm.display_country = null;
    this.addressBookForm.results = [];
    this.addressBookForm.list = [];
    this.addressBookForm.selected = [];

    this.createContactForm.email = null;
    this.createGroupForm.email = null;
    this.createContactForm.groups = [];


  }

/*HIGHLIGHTING THE VIEWED MESSAGE IN THE INBOX LIST */
  checkViewedMsg(msg) {
    if(msg === this.viewedMsg) {
      let msgTop = document.getElementById('msgtop');
      return 'inbox-selected';
    }
  }

/*SENDS EMAILS*/
  sendEmail() {
    this.emailForm['success'] = null;
    this.emailForm['error_msg'] = [];
    this.emailSendLoad = true;
    document.getElementById('etop').scrollIntoView();

    if(this.emailForm.cc.length > 0){
      if(this.emailForm.cc.trim() === '') {
        this.emailForm.cc = this.userData.email;
      }else{
        this.emailForm.cc = this.emailForm.cc + ',' + this.userData.email;
      }
    }else{
      this.emailForm.cc = this.userData.email;

    }

    let email_valid = this.graphService.validateMessage(this.emailForm);
    if(typeof email_valid['action'] == 'undefined') {
      this.emailForm.success = false;
      this.emailForm.error_msg = email_valid;
      this.emailSendLoad = false;
    }
    else {
      email_valid['action'] = 'graph/sendmessage';
      this.restService.postFunction(email_valid).subscribe(response =>{
        this.emailSendLoad = false;
        this.emailForm.success = true;
        setTimeout(() =>{
          this.modalService.dismissAll();
          this.emailForm = {from:this.sessionData.client_email, to:[], bcc:[], cc:[], subject:null ,body:null};
        },2000);
      });
    }

  }


/* SEARCH INBOX EMAILS*/
  searchEmail() {
    this.searchInboxForm['error_msg'] = null;
    let dataObj = {action: 'graph/searchmessages', vals:{}};
    const validateFields = ['from','subject','msg','dt_search'];
    validateFields.forEach(field =>{
      if(field =='dt_search' && this.searchInboxForm[field] != "0") {
        dataObj.vals['search_dt'] = this.searchInboxForm[field];
        if(this.searchInboxForm[field] =='day') {
          if(this.searchInboxForm.day_dt != null) dataObj.vals['day_dt'] = this.makeFriendlyDate(this.searchInboxForm.day_dt,true);
          else this.searchInboxForm.error_msg = 'Please select a day.';
        }
        else {
          if(this.searchInboxForm.to_dt != null && this.searchInboxForm.from_dt != null) {
            dataObj.vals['to_dt']   = this.makeFriendlyDate(this.searchInboxForm.to_dt,true);
            dataObj.vals['from_dt'] = this.makeFriendlyDate(this.searchInboxForm.from_dt,true);
          }
          else this.searchInboxForm.error_msg = 'Date range is incomplete.';
        }
      }
      else {
        if(this.searchInboxForm[field] != null) dataObj.vals[field] = this.searchInboxForm[field];
      }
    });

    if(!Object.keys(dataObj.vals).length) {
      this.searchInboxForm.error_msg = 'Please enter at least one search criteria.';
    }
    if(this.searchInboxForm.error_msg == null) {
      this.searchLoading = true;
      this.restService.postFunction(dataObj).subscribe(response =>{
        if(response.success) {
          this.searchLoading = false;
          this.closeModal();
          this.activeFolder = 'search';
          this.mail[this.activeFolder] = {};
          this.mail[this.activeFolder]['messages'] = response.messages.value;
          this.mail[this.activeFolder]['get_more'] = (response.value['@odata.nextLink'] != 'undefined')? this.mail[this.activeFolder]['get_more'] = response.value['@odata.nextLink'] : null;
        }
        else {
          this.searchLoading = false;
          this.searchInboxForm.error_msg = 'No results found.';
        }
      });
    }
  }


  closeModal() {
    this.modalService.dismissAll();
  }

/*BUILD OUT THE LIST OF CONTACTS. SPLIT INTO EMAILS, COUNTRIES, AND RAW DATA, WHICH IS EXACTLY THAT, UNTOUCHED */
  buildContacts(contacts) {
    this.addressBook.raw_data = contacts;
    this.addressBook.emails = [];

    contacts.forEach(record =>{
      let emailex = this.addressBook.emails.filter(data => data.email.toLowerCase().includes(record.email.toLowerCase()));
      if(!emailex.length) this.addressBook.emails.push({email:record.email});
    });
  }

  validateEmail(email) {
    if(email == null) return false;
    else {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    }
  }

  makeFriendlyDate(date,inbox) {
    var returnDt = null;
    var day   = (date.day.toString().length < 2)? '0'+date.day : date.day;
    var month = (date.month.toString().length < 2)? '0'+date.month : date.month;
    if(inbox) returnDt = month+'/'+day+'/'+date.year;
    else returnDt = date.year+'-'+month+'-'+day;
    return returnDt;
  }

}
