import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

  constructor() { }
  objectKeys = Object.keys;

  pagination(max) {
  	var results = [];
        if(max > 5) results.push(5);
        if(max > 10) results.push(10);
        if(max > 25) results.push(25);
        if(max > 50) results.push(50);
        if(max > 100) results.push(100);
        if(max > 150) results.push(150);
        results.push(max);
        return results;
  }

  dropdownFilters(data) {
	var distinct = {};
	var columns  = [];
	for(let key in data[0]) {
		columns.push(key);
		distinct[key] = [];
	}
	data.forEach(record =>{
		//var filter = "";
		columns.forEach(col =>{
		//	filter += record[col];
			if(record[col] != null && record[col] !='' && record[col] !=' ') {
				if(typeof distinct[col] == 'undefined') {
					distinct[col] = [];
				}
				if(typeof distinct[col] == 'undefined' || distinct[col].indexOf(record[col]) === -1){
					distinct[col].push(record[col]);
				}
			}
		});

		//record['filter'] = filter;con
	});

    for (var key in distinct) {
      distinct[key].sort();
    }


    return distinct;
  }
}
