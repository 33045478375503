/* "If you fail to plan, you are planning to fail." - Benjamin Franklin
	THIS ALL NEEDS TO BE CLEANED UP AT SOME POINT. IT IS A MESS DUE TO TIME CONTSTRAINTS. THIS WHOLE PROJECT IS A MESS, HONESTLY.
*/
import {Component, OnInit, Injectable, ViewChild, HostListener} from '@angular/core';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {TablesService, RestService, TransferService, GraphMailService} from '../../services';
import {NgbModal, NgbTabChangeEvent, NgbDate, NgbCalendar, NgbActiveModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {FilterPipe} from 'ngx-filter-pipe';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import {formatDate} from '@angular/common';
import * as countries_json from '../../models/countries.json';
import * as ClassicEditor from '../../../assets/ckeditor';
// import ClassicEditor from '../../../../ckeditor5-build-classic/build/ckeditor';
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
// import MediaEmbed from ClassicEditor;
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const recdropdowns = ['assignee', 'url_owner_country', 'notice_status', 'is_suspended', 'region'];
const poldropdowns = ['level','office','party_affiliation','candidate_supported','candidate_opposed','paid_by','entity','enhanced_notice','any_notice','ad_choices_icon','political_ads_icon','not_found','not_applicable'];



@Component({
  selector: 'recordmodal',
  templateUrl: './record-modal.component.html',
  styleUrls: ['./record-modal.component.css']
})

export class RecordModalComponent implements OnInit {
  public Editor = ClassicEditor;
  faShareSquare = faShareSquare;
  faEnvelope = faEnvelope;
  faTimes = faTimes;
  faBinoculars = faBinoculars;
  faNewspaper = faNewspaper;
  faCheck = faCheck;
  faPaperPlane = faPaperPlane;
  faEdit = faEdit;
  faPlus = faPlus;
  faUserPlus = faUserPlus;

  search = (text$: Observable<string>) =>
	text$.pipe(debounceTime(200),
	map(term => term === '' ? []: this.dropdownData.assignees.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
);
formatter = (x: {name: string}) => x.name;

@ViewChild('recordModal', {static: false}) private recordModal;
	userData:any      	   = JSON.parse(sessionStorage.getItem('currentUser'));
	screenHeight:any;
	showModal:boolean 	   = false;
	showTranscript:boolean = false;
	loading:boolean   	   = false;
	recordForm:any    	   = {};
	record:string     	   = null;
	noteForm:any      	   = {note:null, success: null};
  distributorForm:any   = {name:null, success: null};
 	dropdownSettings  	   = {singleSelection: true, allowSearchFilter: true, closeDropDownOnSelection: true, textField: 'name', idField:'name'};
 	dropdownSettingsAlt  	   = {singleSelection: true, allowSearchFilter: true, closeDropDownOnSelection: true, textField: 'name', idField:'id'};
 	dropdownData 	  	   = (sessionStorage.getItem('dropdowns') != null)? JSON.parse(sessionStorage.getItem('dropdowns')) : {};
  politicalDropdowns 	  	   = (sessionStorage.getItem('politicalDropdowns') != null)? JSON.parse(sessionStorage.getItem('politicalDropdowns')) : {};
 	historyModal:any  	   = [];
 	today:string           = null;
  sessionData:any         = JSON.parse(sessionStorage.getItem('currentUser'));
  show_url_alt_data:boolean = false;
  show_shopping_link:boolean = false;
  rf: boolean = false;
  ac: boolean = false;
  distributorFieldText: boolean = false;
  distributorDropDown: boolean = false;


  emailForm: any = {
    template_id: [],
    entity: [],
    from: [],
    to: [],
    cc: [],
    bcc: [],
    subject: null,
    body: null,
    infraction_header_id: null,
    next_status: null,
    follow_up_dt: null
  }
  noticelabel:string = null;
  distributorlabel:string = null;
  ownerLabel:string = null;
  socialTwoLabel: string = null;
  phoneTwoLabel: string = null;
  socialThreeLabel: string = null;
  emailFormDropdowns: any = {templates: {settings: {}, data: {}}, entities: {settings: {}, data: {}}};
  address_book = <any>{
    emails: [],
    groups: [],
    raw_data: [],
    emails_selected: [],
    groups_selected: [],
    email_list: [],
    form: null,
    results: []
  };
  address_book_dds: any = {
    emails: {singleSelection: false, allowSearchFilter: true, textField: 'email', idField: 'email', itemsShowLimit: 0, limitSelection: 100},
    groups: {
      singleSelection: true,
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      textField: 'group_name',
      idField: 'id',
      itemsShowLimit: 1
    },
  };
  addContactsToEmail: any = {group: null, contactType: null, contacts: []};


  viewedMsg: any = {};
  emailSendLoad: boolean = false;
  show_political: boolean = false;

	constructor(private modalService: NgbModal,
				private activeM : NgbActiveModal,
				private tablesService: TablesService,
				private restService: RestService,
				private transferService : TransferService,
				private domSanitizer : DomSanitizer,
				private graphService : GraphMailService
	) { this.getScreenSize();
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0');
		var yyyy = today.getFullYear();
		this.today = yyyy+'-'+mm+'-'+dd;
	}

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight / 1.2;
          this.screenHeight = (this.screenHeight < 800)? 800 : this.screenHeight;
    }


	ngOnInit() {
		this.transferService.getData().subscribe(data =>{
		  console.log(data)
			this.recordForm = data.record;
			this.loading = true;
			this.openModal(false,false,false);
			this.showModal = true;

      this.restService.postFunction({'action' : 'services/getinfractiondetails', vals:{infraction_header_id : this.recordForm.infraction_header_id}}).subscribe(data =>{
        // var urlIds = ['22740673', '22646505', '22735578', '22735579', '22790311', '22790264', '22790264', '22790384', '22790398', '22954565', '22954585', '23010449', '23051651', '23050087', '23050088', '23099513'];
        // if(urlIds.includes(this.recordForm['u_id'])){
          this.showTranscript = true;
          this.recordForm.transcript = 'https://services.integrishield.com/php/scripts/transcript.php?url_id='+this.recordForm['u_id'];
        // }else{
        //   this.showTranscript = false;
        // }

        data.enhanced_notice = 0;
          data.details.forEach(item =>{
              if(item.resolved == 'yes') item.resolved = true;
              else item.resolved = false;
              if(this.rf){
                if(item.integrationStatus){
                  item.integrationStatus = item.integrationStatus.toUpperCase();
                }
              }
					});
					this.recordForm['email_history']      = data.email_history;
					this.recordForm['infractions'] = data.details;
					this.recordForm['notes']   = data.notes;
					this.recordForm['integrationStatus'] = data.integrationStatus;
					// let url = 'https://s3.amazonaws.com/compliance_captures/screenshots/'+this.recordForm['crawl_session_id']+'.png';
					let url = 'https://services.integrishield.com/php/scripts/s3cdn.php?image_id='+this.recordForm['crawl_session_id'];
          if(this.recordForm.url.includes('isdisplaymonitor')){
            this.recordForm['iframe_url'] = this.domSanitizer.bypassSecurityTrustResourceUrl(this.recordForm.url);
          }else{
            this.recordForm['iframe_url'] = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
          }
					this.recordForm['screenshot_url'] = url;

          if(JSON.parse(sessionStorage.currentUser).email == "daap@integrishield.com"){
            this.show_political = true;
          }

        //Usana
          if(JSON.parse(sessionStorage.currentUser).clients.includes('96719') || JSON.parse(sessionStorage.currentUser).clients.includes('97816')){
            this.noticelabel = 'Risk Assessment';
          }else{
            this.noticelabel = 'Notice Status';
          }

          if(JSON.parse(sessionStorage.currentUser).clients.includes('97816')){
            this.distributorlabel = 'Marketing Executive';
          }else{
            this.distributorlabel = 'Distributor ID';
          }

          if(JSON.parse(sessionStorage.currentUser).clients.includes('8715')){
            this.distributorFieldText = true;
          }else{
            this.distributorDropDown = true;
          }

          //Nuskin
          if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
            this.ownerLabel = 'Owner Market';
            this.distributorlabel = 'Affiliate Name';
            this.socialTwoLabel = 'Pin Title';
            this.socialThreeLabel = 'Affiliate ID';
            this.phoneTwoLabel = 'Status';
          }else{
            this.ownerLabel = 'Owner Country';
            this.distributorlabel = 'Distributor ID';
            this.socialTwoLabel = 'Social Two';
            this.phoneTwoLabel = 'Phone Two';
          }

          //Rodan and Fields
          if(JSON.parse(sessionStorage.currentUser).clients.includes('95687')){
            this.rf = true;
          }

          //Advocare
          if(JSON.parse(sessionStorage.currentUser).clients.includes('99301') || JSON.parse(sessionStorage.currentUser).clients.includes('2683')){
            this.ac = true;
          }

          if(JSON.parse(sessionStorage.currentUser).clients.includes('96764') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('96719') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('8715')  ||
             JSON.parse(sessionStorage.currentUser).clients.includes('97234') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('97816') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('95687') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('2683')  ||
             JSON.parse(sessionStorage.currentUser).clients.includes('99301') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('99141') ||
             JSON.parse(sessionStorage.currentUser).clients.includes('97680') ) {
            this.show_url_alt_data = true;
          }
          else{
            this.show_url_alt_data = false;
          }

          if(JSON.parse(sessionStorage.currentUser).clients.includes('95687') ||
            JSON.parse(sessionStorage.currentUser).clients.includes('97816') ||
            JSON.parse(sessionStorage.currentUser).clients.includes('5864')  ||
            JSON.parse(sessionStorage.currentUser).clients.includes('2683')  ||
            JSON.parse(sessionStorage.currentUser).clients.includes('96719') ||
            JSON.parse(sessionStorage.currentUser).clients.includes('97816') ||
            JSON.parse(sessionStorage.currentUser).clients.includes('96764') ||
            JSON.parse(sessionStorage.currentUser).clients.includes('98787') ) {
            this.show_shopping_link = true;
          }
          else{
            this.show_shopping_link = false;
          }


          /*MUST PUT DROPDOWN VALUES IN ARRAY FOR DROPDOWN TO WORK*/
					let country = this.recordForm.url_owner_country;
					let suspendstatus = this.recordForm.is_suspended;
					let notice = this.recordForm.notice_status;
					let region = this.recordForm.region;
					let assignee = this.recordForm.assignee;
					let affiliate_name = this.recordForm.affiliate_name;
					let landing_page = this.recordForm.landing_page;
					let url_owner = this.recordForm.url_owner_email;
					// let distributor_id = this.recordForm.distributor_id;
					let fud       = (this.recordForm.follow_up_dt != null && this.recordForm.follow_up_dt.length)? this.recordForm.follow_up_dt.split("-"):null;
					if(fud != null) this.recordForm.follow_up_dt =  {year : +fud[0], month: +fud[1], day:+fud[2]};
					this.recordForm['owner_email'] = (url_owner != null && url_owner.length)? url_owner : null;
					this.recordForm.assignee = {name : assignee};

					if(country != null && country.length) {
						this.recordForm.url_owner_country = [];
						this.recordForm.url_owner_country.push(country);
					}
					else this.recordForm.url_owner_country = [];



        if(region != null && region.length) {
          this.recordForm.region = [];
          this.recordForm.region.push(region);
        }
        else this.recordForm.region = [];

					// if(distributor_id != null && distributor_id.length) {
					// 	this.recordForm.distributor_id = [];
					// 	this.recordForm.distributor_id.push(distributor_id);
					// }
					// else this.recordForm.distributor_id = [];

					if(suspendstatus != null && suspendstatus.length) {
						this.recordForm.is_suspended = [];
						this.recordForm.is_suspended.push(suspendstatus);
					}
					else this.recordForm.is_suspended = [];

					if(notice != null && notice.length) {
						this.recordForm.notice_status = [];
						this.recordForm.notice_status.push(notice);
					}
					else this.recordForm.notice_status = [];

					this.record = JSON.stringify(this.recordForm);

          var ccemails = [];
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if(JSON.parse(sessionStorage.currentUser).clients.includes('8715')){
            if(re.test(String(this.recordForm.assignee.name).toLowerCase()) ){
              ccemails.push(this.recordForm.assignee.name);
            }
            if(re.test(String(JSON.parse(sessionStorage.currentUser).email).toLowerCase()) ){
              ccemails.push(JSON.parse(sessionStorage.currentUser).email);
            }
          }else if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
            if(re.test(String(JSON.parse(sessionStorage.currentUser).email).toLowerCase()) ){
              ccemails.push(JSON.parse(sessionStorage.currentUser).email);
            }
          }

					/*EMAIL FORM CHECK FOR ERROR. IF ERROR, DISPLAY ON SHOW EMAIL*/
					if(typeof data['error'] =='undefined') {
						this.buildEmailDropdowns(data.email_details);

						Object.keys(this.emailForm).forEach(key =>{
							if(key =='template_id') {
								data.email_details.templates.forEach(tmp =>{

									if(+tmp.ts_id === data.email_details.email_template.template_id) {
										this.emailForm[key].push(tmp);
									}
								});
							}
							else if(key =='cc' || key =='bcc' || key == 'to') {
								if(data.email_details.email_template.cc != null) this.emailForm[key] = [];
								else this.emailForm[key] = data.email_details.email_template[key];
								if(key == 'to' && JSON.parse(sessionStorage.currentUser).client_email != 'dms@integrishield.com'){
								  if(url_owner !== ' '){
                    this.emailForm[key] = url_owner;
                  }
                }
                if(key == 'cc' && ccemails.length > 0){
                  this.emailForm[key] = ccemails.join(",");
                }
							}
							else this.emailForm[key] = data.email_details.email_template[key];
						});
					}
					else this.emailForm['temp_error'] = data.error.error;

					this.dropdownData = JSON.parse(sessionStorage.getItem('dropdowns'));
					this.politicalDropdowns = JSON.parse(sessionStorage.getItem('politicalDropdowns'));
					this.loading = false;
			});

		});

	}

	updateDropdowns(){
    this.dropdownData = JSON.parse(sessionStorage.getItem('dropdowns'));
  }

	openModal(modal, email, emailField) {
		if(modal === false) {
			this.modalService.open(this.recordModal,{size : <any>'xlg',backdrop : 'static'});
		}

 	    else {
	    	if(emailField) {
		    	this.address_book.form            = emailField;
		    	this.address_book.emails_selected = Object.assign([],this.emailForm[emailField]);
		    	this.address_book.email_list      = Object.assign([],this.emailForm[emailField]);
	    	}
	    	else {
	    		this.emailForm['success'] = <boolean>null;
				this.emailForm['success_msg'] = <any>[];
				this.addContactsToEmail = {country:null,contactType:null,contacts:[]};
	    	}
 	    	this.activeM = this.modalService.open(modal,{size : (email)? 'lg' : 'lg',backdrop : 'static', windowClass :(emailField)?'dark-modal' :''});
 	    }
 	}
/*MODAL SPECIFIC TO OPENING THE ADD CONTACTS TO EMAIL */
 	openContactsModal(modal,contact) {
 		this.addContactsToEmail.contactType = contact;
 		this.activeM = this.modalService.open(modal,{size : 'lg',backdrop : 'static', windowClass :'dark-modal'});
 	}

	updateRecord() {
		document.getElementById('top').scrollIntoView();
		let dataObj = {action: 'services/updateinfractions', vals : {}};
		let newVals = {};
		let polVals = {};

		if(this.recordForm.url_owner_email.length !== ' '){
      this.emailForm.to = this.recordForm.url_owner_email;
    }

	    Object.keys(this.recordForm).forEach(key =>{
			if(recdropdowns.includes(key)) {
				if(key =='assignee') {
				if(typeof this.recordForm[key] == 'object') {
				 if(typeof this.recordForm[key]['name'] !='undefined') newVals[key] = this.recordForm[key]['name'];
				 else newVals[key] = null;
				}
				else {
				newVals[key] = (this.recordForm[key] != "undefined")? this.recordForm[key] : null;
				}
				}
				else {
					newVals[key] = (!this.recordForm[key].length)? "null" : (typeof this.recordForm[key][0] == 'string')? this.recordForm[key][0] : this.recordForm[key][0].name;
				}
			}
			else {
				if(key ==='false_positive' || key ==='customer_review') {
					newVals[key] = (this.recordForm[key] === 'true' || this.recordForm[key] === true)? '1' : '0';
				}
				else {
					if(typeof this.recordForm[key] =='string' && this.recordForm[key].length) newVals[key] = this.recordForm[key];
					else if(typeof this.recordForm[key] =='boolean' && this.recordForm[key] !='undefined') {
					if(this.recordForm[key] === true) newVals[key] =  "1";
					else newVals[key] = "0";

					}
					else if(key =='follow_up_dt') newVals[key] = (this.recordForm[key] != null)? this.recordForm[key] : null;
					else if(key !='records') newVals[key] = "null";
					else newVals[key] = this.recordForm[key];
				}
			}
	    });


	    Object.keys(newVals).forEach(key =>{
	      if(newVals[key] === true) dataObj.vals[key] = '1';
	      else if(newVals[key] === false) dataObj.vals[key] = '0';
	      else dataObj.vals[key] = newVals[key];
	      if(key =='follow_up_dt') {
	        if(newVals[key] !=null) dataObj.vals[key] = this.makeFriendlyDate(newVals[key]);
	        else dataObj.vals[key] = "null";
	      }

	    });

	    let dist = JSON.parse(sessionStorage.dropdowns).distributors;

      // for (var i=0; i < dist.length; i++) {
      //   if (dist[i].name === dataObj.vals['distributor_id']) {
      //     dataObj.vals['compliance_distributor_id'] = dist[i].id
      //   }
      // }

	    dataObj.vals['owner_country'] = dataObj.vals['url_owner_country'];
	    delete dataObj.vals['url_owner_country'];

      dataObj.vals['records'] = [{url_id: dataObj.vals['u_id'], infraction_header_id: dataObj.vals['infraction_header_id'], suspended_owner_email: dataObj.vals['url_owner_email'], suspended_status: dataObj.vals['is_suspended']}];
      delete  dataObj.vals['u_id'];
      delete  dataObj.vals['infraction_header_id'];

	    dataObj.vals['owner_email'] = dataObj.vals['url_owner_email'];
	    delete dataObj.vals['url_owner_email'];

	    dataObj.vals['suspended_status'] = dataObj.vals['is_suspended'];
	    delete dataObj.vals['is_suspended'];


	    dataObj.vals['suspended_status'] = dataObj.vals['is_suspended'];
	    delete dataObj.vals['is_suspended'];

      console.log(dataObj)

	    this.restService.postFunction(dataObj).subscribe(response =>{
	    	/*IF NEW ASSIGNEE ADDED, UPDATE DROPDOWN WITH NEW ASSIGNEE */
	    	if(dataObj.vals['assignee'] != null) {
				var ssdd = JSON.parse(sessionStorage.getItem('dropdowns'));
				let newAssign = ssdd.assignees.filter(data => data.name.toLowerCase().includes(dataObj.vals['assignee'].toLowerCase()));
				if(!newAssign.length)	ssdd.assignees.push({name: dataObj.vals['assignee']});
				sessionStorage.setItem('dropdowns',JSON.stringify(ssdd));
				this.dropdownData.assignees = ssdd.assignees;
	    	}

	     	if(response.records) {
	      		this.recordForm['success'] = true;
	      		this.recordForm['msg']     = 'Record details updated successfully.';
	      		setTimeout(() =>{
	      			delete this.recordForm.success;
	      			delete this.recordForm.msg;
	      		},2000);
	     	}
	     	//QUICK FIX TO STOP TRIGGERING CHECKBOX ON DASHBOARD REPORT
        if (response.records[0].client_followup == '0') {
          response.records[0].client_followup = null;
        }
        this.transferService.updateData(response.records[0]);
	  	});
	}

	addNote() {
		if(this.noteForm.note != null) {
			this.restService.postFunction({action:'services/createurlnote', vals :{note : this.noteForm.note, url_id: this.recordForm.u_id, infraction_header_id: this.recordForm.infraction_header_id}}).subscribe(response =>{
				this.recordForm.notes = [];
				this.recordForm.notes = response.notes;
				this.noteForm.note = '';
				this.noteForm.success = true;
				setTimeout(() =>{ this.activeM.close();},1500);
			});
		}
	}


  addDistributor() {
		if(this.distributorForm.name != null) {
			this.restService.postFunction(
			  {action:'services/createDistributor', vals :
            {distributor_name : this.distributorForm.name, }
			  }
      ).subscribe(response =>{
				setTimeout(() =>{ this.activeM.close();},1500);
			});
		}
	}

  noteDelete(item){
    if(confirm("Are you sure you want to delete this note: " + item.note)) {
      this.restService.postFunction({action:'services/deleteurlnotebyid', vals :{noteid : item.id, infraction_header_id: this.recordForm.infraction_header_id}}).subscribe(response =>{
        this.recordForm.notes = [];
        this.recordForm.notes = response.notes;
      });
    }
 	}

	submitInfractionResolution(event,details_id) {
		let dataObj = {action :'services/resolveinfractiondetails',
					   vals   : {infraction_details_id:details_id,
					   			 resolved : (event)? 'yes':'no',
					   			 infraction_header_id : this.recordForm.infraction_header_id
					   			}
					  };
		this.restService.postFunction(dataObj).subscribe(response =>{
			this.recordForm.infractions.forEach(inf =>{
				if(inf.infraction_details_id == details_id)
				inf.history.push({resolved: (event)?'Yes':'No', resolved_by : this.userData.client_email, resolution_dt: this.today});
			});
		});
	}

	getTemplate(ev) {
		let data = {};

		data['ts_id'] = this.emailForm.template_id[0].ts_id;
		data['ts_name'] = this.emailForm.template_id[0].ts_name;
		if(this.emailForm.entity){
		  if(this.emailForm.entity.length > 0){
        data['entity_name'] = this.emailForm.entity[0].entity_name;
      }
    }

		data['infraction_header_id'] = this.recordForm.infraction_header_id;
		data['infraction_header_ids'] = this.recordForm.infraction_header_id;

		var dataObj = {action : 'services/retrievetemplate',vals: data};

		this.restService.postFunction(dataObj).subscribe(response =>{
			Object.keys(this.emailForm).forEach(key =>{
				const ignore = ['infraction_header_id','entity','success','success_msg', 'to', 'cc', 'bcc'];
				if(ignore.indexOf(key) == -1) {
					if(key =='template_id') {
						this.emailFormDropdowns.templates.data.forEach(tmp =>{

							if(+tmp.ts_id === response.template_id) {
								this.emailForm[key].push(tmp);
							}
						});
					}
					else this.emailForm[key] = response[key];
				}
			});
		});
	}

	sendEmail() {
		this.emailSendLoad = true;
		this.emailForm['success'] = null;
		this.emailForm['success_msg'] = [];
		document.getElementById('etop').scrollIntoView();

		let email = this.graphService.validateMessage(this.emailForm);

		if(typeof email['action'] == 'undefined') {
			this.emailSendLoad = false;
			this.emailForm['success'] = false;
			this.emailForm['success_msg'] = email;
		}
		else {
			email['action'] = 'services/sendinfractionemail';
			this.restService.postFunction(email).subscribe(response =>{
				if(response.graphResponse === false){
          this.emailSendLoad = false;
          this.emailForm.success = false;
          this.emailForm.success_msg.push('Rejected by the email provider. Please contact your administrator.');
        }else{
          this.emailSendLoad = false;
          this.recordForm.email_history = response.email_history;
          this.emailForm.success = true;
          let transferObject = {u_id: response.urlID, notice_status: response.notice_status, follow_up_dt: response.follow_up_dt};
          this.transferService.updateData(response.records[0]);
          setTimeout(() =>{
            this.emailForm = {
              template_id: [],
              entity: [],
              from: [],
              to: [],
              cc: [],
              bcc: [],
              subject: null,
              body: null,
              infraction_header_id: null,
              next_status: null,
              follow_up_dt: null
            };
            this.activeM.close();
          },2000);
        }


			});
		}
	}


	getContacts() {
    this.addContactsToEmail.contacts = [];

    let emails = this.address_book.raw_data.filter(data => data.group_name.toLowerCase().indexOf(this.addContactsToEmail.group_name[0].group_name.toLowerCase()) > -1);
		emails.forEach(em =>{
		    const match = this.addContactsToEmail.contacts.filter(data => data == em.email);
		    if(!match.length)
		    	this.addContactsToEmail.contacts.push(em.email);
		});
	}

	addContacts() {
		const type = this.addContactsToEmail.contactType;
		let emails = []; //(!this.emailForm[type].length) ? [] : this.emailForm[type].split(',');
		if(typeof this.emailForm[type] =='object') {
      if(this.emailForm[type]){
        this.emailForm[type].forEach(em =>{
          emails.push(em);
        });
      }
		}
		else {
			emails = (!this.emailForm[type].length) ? [] : this.emailForm[type].split(',');
		}
		this.emailForm[type] = [];

		this.addContactsToEmail.contacts.forEach(c =>{
		    let match = emails.filter(em => em == c);
		    if(!match.length) emails.push(c);
		});
		this.emailForm[type] = emails;
		this.addContactsToEmail = {country:null,contactType:null,contacts:[]};
		this.activeM.close();
	}

/* BUILD DROPDOWNS FOR EMAIL FORM */
	buildEmailDropdowns(data) {
			/*SELECT ALL DISTINCT EMAIL ADDRESSES REGARDLESS OF COUNTRY */
			/*SELECT ALL DISTINCT COUNTRIES */
		this.address_book.raw_data = data.address_book;

		data.address_book.forEach(record =>{
			let emailex = this.address_book.emails.filter(data => data.email.toLowerCase().includes(record.email.toLowerCase()));
			if(!emailex.length) this.address_book.emails.push({email:record.email});

			// this.address_book.countries = countries_json.default;
		});
		Object.keys(this.dropdownSettings).forEach(key =>{
			if(key =='textField' || key == 'idField') {
				this.emailFormDropdowns.templates.settings['textField'] = 'ts_name';
				this.emailFormDropdowns.templates.settings['idField'] = 'ts_id';
				this.emailFormDropdowns.entities.settings['textField'] = 'cl';
				this.emailFormDropdowns.entities.settings['idField'] = 'entity_name';
			}
			else {
				this.emailFormDropdowns.templates.settings[key] = this.dropdownSettings[key];
				this.emailFormDropdowns.entities.settings[key] = this.dropdownSettings[key];
			}
		});
		this.emailFormDropdowns.templates.data = data.templates;
		this.emailFormDropdowns.entities.data = data.entities;

	}

  getContactGroups(event) {
    let data = {};

    data['company'] = this.sessionData.clients[0];

    var dataObj = {action : 'services/getGroups', vals: data};
    this.restService.postFunction(dataObj).subscribe(response =>{
      this.address_book['groups'] = response.data;
    });

  }

	makeFriendlyDate(date) {
		var returnDt = null;
		var day   = (date.day.toString().length < 2)? '0'+date.day : date.day;
		var month = (date.month.toString().length < 2)? '0'+date.month : date.month;
		returnDt = date.year+'-'+month+'-'+day;
		return returnDt;
	}

	validateEmail(email) {
	  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	  return re.test(email.toLowerCase());
	}

/*USED FOR ADJUSTING IFRAME TO SCREEN RESOLUTION */
	getMeta(url){
	    var img = new Image();
	    img.addEventListener("load", function(){
	        return this.naturalHeight;
	    });

	}


}
