import { Component, OnInit }                  from '@angular/core';
import { Router, ActivatedRoute }             from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first }                              from 'rxjs/operators';
import { AuthenticationService }              from '../../services';
import {NgbModal}                             from '@ng-bootstrap/ng-bootstrap';
import sha256                                 from 'crypto-js/sha256';
import { HttpClient,HttpHeaders, HttpParams }                  from '@angular/common/http';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    faExclamationTriangle = faExclamationTriangle;

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    accessToken = (!sessionStorage.getItem('accessToken'))? null : sessionStorage.getItem('accessToken');
    error = null;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private modalService: NgbModal,
        private http: HttpClient
    ) {

    }

    ngOnInit() {
        //this.getGuestSession();
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
        // reset login status
        //this.authenticationService.logout();

        // get return url from route parameters or default to '/'
       // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }



    // getGuestSession() {
    //     this.authenticationService.getGuestSession().pipe(first())
    //         .subscribe(data => {
    //           this.sessionKey = data;
    //             //return data;
    //         });
    // }

  forgotModal(content,record) {
      this.modalService.open(content,{size : <any>'md',backdrop : 'static',windowClass: 'dark-modal'});
  }

  // submitForgot() {
  //    this.authenticationService.forgotPassword(this.forgotForm.email).pipe(first())
  //           .subscribe(data => {
  //           });

  // }

    onSubmit() {
        this.submitted = true;
        this.error = null;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.error = "Please enter an email and password.";
            this.submitted = false;
            return;
        }

        this.loading = true;

        //this.authenticationService.login(this.f.email.value, sha256(this.f.password.value).toString(),this.sessionKey)
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if(!data.success) {
                        this.error = data.message;
                        this.submitted = false;
                    }
                    else {
                            this.router.navigate(['/']);
                            location.reload();
                    }
                });
    }
}
