import { Component, OnInit }    from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestService }          from '../../services';
import { NgbTooltip,NgbModal, NgbTabChangeEvent, NgbDate, NgbCalendar } 		    from '@ng-bootstrap/ng-bootstrap';
import * as XLSX      		    from 'xlsx';
import * as infractions         from '../../models/chaseReport_infractions.json';
import * as infraction_users         from '../../models/infraction_report_users.json';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  faSearchPlus = faSearchPlus;
  faTachometerAlt = faTachometerAlt;

	hoveredDate: NgbDate;
	fromDate: NgbDate;
	toDate: NgbDate;
	loading         = <boolean>false;
	reportData      = <any>{infractions: null, data: [], domains: [], clients: [], headers: []};
	reportMsg       = <string>null;
	showReportUsers = infraction_users.default;
	reportUser      = <any>{email: null, clients: []};
	showReport      = <boolean>false;
	client 			= <any>null;

	constructor(private restService: RestService,
				private calendar: NgbCalendar,
				private modalService: NgbModal) {
	}

	ngOnInit() {
		const user = JSON.parse(sessionStorage.getItem('currentUser'));
		this.reportUser = this.showReportUsers.filter(data => data.email.toLowerCase() === user.email.toLowerCase())[0];
		if(typeof this.reportUser != 'undefined') {
			this.showReport = true;
			if(this.reportUser.clients.length === 1) this.client = this.reportUser.clients[0].value;
		}
	}

	downloadReport() {
		if(this.client === null) {
			this.reportMsg = 'No client selected.';
			setTimeout(() => {this.reportMsg = null;},2000);
		}
		else {
			this.loading = true;
			this.reportMsg = null;
			const from_dt = this.makeFriendlyDate(this.fromDate);
			const to_dt   = this.makeFriendlyDate(this.toDate);
			const infraction_list = infractions.default[this.client];

			/* ASSEMBLE DATA */
			this.restService.postFunction({action:'services/infractionreport', vals:{'from_date' : from_dt, 'to_date': to_dt, client : this.client}}).subscribe(response =>{
				if(response.data.length > 0) {
					response.data.forEach(data =>{
						const newDomain = this.reportData.domains.includes(data.Domain);
						if(!newDomain) this.reportData.domains.push(data.Domain);
					});

					this.reportData.data = response.data;
					response.clients.forEach(cli =>{
						this.reportData.clients.push(cli.client_name);
						this.reportData.headers.push(cli.client_name);
					});

					/*DATA BUILT, NOW BUILD ROWS*/
					var rows = [];
					this.reportData.domains.forEach(domain =>{
						infraction_list.forEach(inf =>{
							let row = {};
							row['Domain'] = domain;
							row['Product'] = inf;
							var rc = 2;
							this.reportData.clients.forEach(client =>{
								let matches = [];
								matches = this.reportData.data.filter(data => data.Domain === domain && data.Client.toLowerCase() === client.toLowerCase() && data['Infraction Type'].toLowerCase() === inf.toLowerCase());
								if(matches.length > 0) row[client] = matches[0]['Infraction Name'];
								else row[client] = 'N/A';
								rc++;
							});
							rows.push(row);
						});
					});
					/* EXPORT DATA TO EXCEL */
			  		const fileName = this.client+'_Infraction_Report_'+from_dt+'-'+to_dt+'.xlsx';
					const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
					const wb: XLSX.WorkBook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(wb, ws, 'Infraction Report');
					XLSX.writeFile(wb, fileName);
					this.loading = false;
					this.modalService.dismissAll();
				}
				else {
					this.loading = false;
					this.reportMsg = 'No results found.';
					setTimeout(() => {this.reportMsg = null;},2000);
				}
			});
		}
	}

	openModal(content) {
		this.loading = false;
	    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -7);
	    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);
	    if(this.reportUser.clients.length > 1) this.client = null;
		this.modalService.open(content,{size : <any>'md',backdrop : 'static',windowClass: 'dark-modal'});
	}

	activeRoute(routename){
	    return window.location.href.indexOf(routename) > -1;
	}

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  makeFriendlyDate(date) {
  	var returnDt = null;
  	var day   = (date.day.toString().length < 2)? '0'+date.day : date.day;
  	var month = (date.month.toString().length < 2)? '0'+date.month : date.month;
  	returnDt = date.year+'-'+month+'-'+day;
  	return returnDt;
  }

}
