import {Component, OnInit, Injectable, ViewChild} from '@angular/core';
import {TablesService, RestService, TransferService, GraphMailService} from '../../services';
import {NgbModal, NgbTabChangeEvent, NgbDate, NgbCalendar, NgbTypeahead, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {FilterPipe} from 'ngx-filter-pipe';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import {formatDate} from '@angular/common';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as columnHeaders from '../../models/remediationLogColumns.json';
import * as countries_json from '../../models/countries.json';
import {MatMenuTrigger} from '@angular/material';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as ClassicEditor from '../../../assets/ckeditor';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


const bulkdropdowns = ['assignee', 'url_owner_country', 'notice_status', 'is_suspended', 'region'];
const bulkchk = ['customer_review', 'false_positive', 'resolved'];

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public Editor = ClassicEditor;
  faSync = faSync;
  faCheck = faCheck;
  faEdit = faEdit;
  faEnvelope = faEnvelope;


  @ViewChild(MatMenuTrigger, {static: false})   contextMenu: MatMenuTrigger;


  search = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200),
      map(term => term === '' ? []: this.dropdownData.assignees.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );
  formatter = (x: {name: string}) => x.name;


  loading: boolean = true;
  cacheLoad: boolean = true;
  addUrlButton: boolean = false;
  userData = JSON.parse(sessionStorage.getItem('currentUser'));
  sessionData: any = JSON.parse(sessionStorage.getItem('currentUser'));
  wideColumn = JSON.parse(sessionStorage.currentUser).clients.includes('8715');
  dropdownSettings = <any>{
    singleSelection: true,
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'name'
  };
  dropdownSettingsAlt = {
    singleSelection: true,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'name'
  };
  dropdownSettingsAltAlt = {
    singleSelection: true,
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'id'
  };
  advSearchDropdownSettings = <any>{
    singleSelection: false,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'id'
  };
  infractionDropdownSettings = <any>{
    singleSelection: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'id'
  };
  advSearchDropdownSettingsAlt = <any>{
    singleSelection: false,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'name'
  };
  advSearchDropdownSettingsAltTwo = <any>{
    singleSelection: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: 'name',
    idField: 'name'
  };
  inboxNewMsg = <number>0;
  inboxNewMsgLoad = <boolean>false;
  dropdownData = (sessionStorage.getItem('dropdowns') != null) ? JSON.parse(sessionStorage.getItem('dropdowns')) : {};
  politicalDropdowns = (sessionStorage.getItem('politicalDropdowns') != null) ? JSON.parse(sessionStorage.getItem('politicalDropdowns')) : {};

  reports                 = <any>{remediation: {friendly : "Remediation Log",
      columns : [],
      showReport : true,
      mfdata : [],
      count : 0,
      page: 1,
      resultsShown: 10,
      resultPagination : [],
      filters : <any>{dropdowns : []}
    }
  };
  emailForm: any = {from: this.sessionData.client_email, to: [], bcc: [], cc: [], subject: null, body: null, flag: null};
  reportsRaw = <any>{remediation: null};
  layoutForm = <any>{columns: []};
  advSearchForm = <any>{
    infraction_types: null,
    assignees: null,
    notice_status: null,
    suspended_status: null,
    clients: null,
    domains: null,
    violation_status: null,
    distributors: null,
    url_owner_email: null,
    url_id: null,
    url: null,
    url_owner_country: null
  };
  distributorForm = <any>{
    distributor_name: null,
    client_distributor_id: null
  };
  urlInfractionForm = <any>{
    url: null,
    assignee: null,
    domains: null,
    infractions: null,
    domain_owner: null,
    owner_country: null,
    site_type: null,
    url_owner: null,
    url_follow_date: null,
    url_discovery_date: null,
    social_one: null,
    distributor_id: null
  };
  urlField = <any>{
    assignees: [],
    domains: [],
    infractions: [],
    notice_status: [],
    violation_status: [],
    infraction_types: [],
    clients: [],
    suspended_status: [],
    distributors: [],
    url_owner_email: null
  };
  urlFieldSelected = <any>{
    assignees: [],
    domains: [],
    infractions: [],
    notice_status: [],
    violation_status: [],
    infraction_types: [],
    clients: [],
    suspended_status: [],
    distributor_ids: [],
    url_owner_email: null
  };
  bulkActions = <any>{
    records: [],
    assignee: null,
    url_owner_country: null,
    notice_status: null,
    is_suspended: null,
    follow_up_dt: null,
    distributor_id: null,
    url_owner_email: null,
    false_positive: false,
    customer_review: false,
    resolved: false,
    note: null,
    social_one: null,
    social_two: null,
    social_three: null,
    phone_one: null,
    phone_two: null,
    region: null,
    affiliate_name: null,
    landing_page: null
  };
  defaultDates = <any>{to: null, from: null, toFriendly: null, fromFriendly: null};
  tableDropdown = <any>{singleSelection: false, unSelectAllText: 'UnSelect All', closeDropDownOnSelection: true};
  remediationDates = <any>{to_date: {picker: NgbDate, friendly: null}, from_date: {picker: NgbDate, friendly: null}};
  advancedSearchDates = <any>{adv_to_date: {picker: NgbDate, friendly: null}, adv_from_date: {picker: NgbDate, friendly: null}};
  urlFormDates = <any>{url_follow_date: {picker: NgbDate, friendly: null}, url_discovery_date: {picker: NgbDate, friendly: null}};
  bulkEmailForm = <any>{
    template_id: [],
    entity: [],
    from: [],
    to: [],
    cc: [],
    bcc: [],
    subject: null,
    body: null,
    next_status: null,
    infraction_header_id: []
  };
  bulkEmailFormDropdowns = <any>{templates: {settings: {}, data: {}}, entities: {settings: {}, data: {}}};
  addressBook = <any>{emails: [], groups: [], raw_data: []};
  addressBook_dds = <any>{
    groups: {
      singleSelection: true,
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      textField: 'group_name',
      idField: 'id',
      itemsShowLimit: 1
    },
    add_countries: {singleSelection: false, allowSearchFilter: true, textField: 'country_name', idField: 'country_id', itemsShowLimit: 4},
    add_emails: {singleSelection: false, allowSearchFilter: true, textField: 'email', idField: 'email', itemsShowLimit: 2}
  };
  addContactsToEmail = <any>{group: null, contactType: null, contacts: []};
  addressBookForm = <any>{
    search_by: '',
    email: null,
    display_email: null,
    country: null,
    display_country: null,
    results: [],
    list: [],
    selected: []
  };
  getCache = <boolean>false;
  emailSendLoad = <boolean>false;
  advSearchFields = <any>{
    assignees: [],
    domains: [],
    notice_status: [],
    violation_status: [],
    infraction_types: [],
    clients: [],
    suspended_status: [],
    distributors: [],
    url_owner_email: null
  };
  advSearchSelected = <any>{
    assignees: [],
    domains: [],
    notice_status: [],
    violation_status: [],
    infraction_types: [],
    clients: [],
    suspended_status: [],
    distributor_ids: [],
    url_owner_email: null
  };
  bulkUrlEmailOwners = <any>{emails: []};
  bulkEmailCC = <any>{emails: []};
  noticelabel:string = null;
  distributorlabel:string = null;
  socialThreeLabel:string = null;
  phoneTwoLabel:string = null;
  socialTwoLabel:string = null;
  ownerLabel:string = null;
  show_url_alt_data:boolean = false;
  show_political:boolean = false;
  showFollowUpDate:boolean = false;
  ac:boolean = false;

  contextMenuPosition = {x: '0px', y: '0px'};

  constructor(private modalService: NgbModal,
              private tablesService: TablesService,
              private restService: RestService,
              private transferService : TransferService,
              private domSanitizer : DomSanitizer,
              calendar: NgbCalendar,
              private graphService:GraphMailService,
              private activeM : NgbActiveModal,

  ) {
    library.add(fas);

    if(JSON.parse(sessionStorage.currentUser).clients.includes('2683') ||
       JSON.parse(sessionStorage.currentUser).clients.includes('98787') ||
       JSON.parse(sessionStorage.currentUser).clients.includes('8715'))
    {
      this.addUrlButton = true;
    }

    this.reports.remediation.columns = (this.userData.report_temp != null)? JSON.parse(this.userData.report_temp.template) : columnHeaders.default;

    if(JSON.parse(sessionStorage.currentUser).clients.includes('8715')){
      this.defaultDates.from = calendar.getNext(calendar.getToday(), 'd', -1);
    }else if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
      this.defaultDates.from = calendar.getNext(calendar.getToday(), 'd', -30);
    }else{
      this.defaultDates.from = calendar.getNext(calendar.getToday(), 'd', -7);
    }
    this.defaultDates.to   = calendar.getToday();
    this.remediationDates  = {'to_date' : {picker:this.defaultDates.to, friendly:this.makeFriendlyDate(this.defaultDates.to)}, 'from_date' : {picker:this.defaultDates.from, friendly:this.makeFriendlyDate(this.defaultDates.from)}};
    this.advancedSearchDates  = {'adv_to_date' : {picker:this.defaultDates.to, friendly:this.makeFriendlyDate(this.defaultDates.to)}, 'adv_from_date' : {picker:this.defaultDates.from, friendly:this.makeFriendlyDate(this.defaultDates.from)}};
    this.urlFormDates  = {'url_follow_date' : {picker:this.defaultDates.to, friendly:this.makeFriendlyDate(this.defaultDates.to)}, 'url_discovery_date' : {picker:this.defaultDates.to, friendly:this.makeFriendlyDate(this.defaultDates.to)}};
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.inboxNewMsgLoad = true;
    this.reports.remediation.columns.forEach(col =>{
      this.reports.remediation.filters[col.name] = '';
    });

    if(JSON.parse(sessionStorage.currentUser).clients.includes('96719') || JSON.parse(sessionStorage.currentUser).clients.includes('97816')){
      this.noticelabel = 'Risk Assessment';
    }else{
      this.noticelabel = 'Notice Status';
    }

    if(JSON.parse(sessionStorage.currentUser).clients.includes('97816')){
      this.distributorlabel = 'Marketing Executive';
    }else{
      this.distributorlabel = 'Distributor ID';
    }

    if(JSON.parse(sessionStorage.currentUser).clients.includes('99301') || JSON.parse(sessionStorage.currentUser).clients.includes('2683') ){
      this.ac = true;
    }

    if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
      this.ownerLabel = 'Owner Market';
      this.distributorlabel = 'Affiliate Name';
      this.socialThreeLabel = 'Affiliate ID';
      this.socialTwoLabel = 'Pin Title';
      this.phoneTwoLabel = 'Status';
    }else{
      this.ownerLabel = 'Owner Country';
      this.socialTwoLabel = 'Social Two';
      this.distributorlabel = 'Distributor ID';
      this.phoneTwoLabel = 'Phone Two';
    }

    if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')  ||
        JSON.parse(sessionStorage.currentUser).clients.includes('96719') ||
        JSON.parse(sessionStorage.currentUser).clients.includes('8715')  ||
        JSON.parse(sessionStorage.currentUser).clients.includes('97234') ||
        JSON.parse(sessionStorage.currentUser).clients.includes('95687') ||
        JSON.parse(sessionStorage.currentUser).clients.includes('99141') ||
        JSON.parse(sessionStorage.currentUser).clients.includes('99301') ||
        JSON.parse(sessionStorage.currentUser).clients.includes('97680') ) {
        this.show_url_alt_data = true;
    }
    else{
      this.show_url_alt_data = false;
    }



    this.getCache = (sessionStorage.getItem('dropdowns') != null)? false : true;
    this.getRemediationData(false);

    this.reports.remediation.columns.forEach(item=>{
      this.layoutForm.columns.push(Object.assign({},item));
    });

    this.transferService.getUpdate().subscribe(data =>{

      /*UPDATE BOTH SETS OF DATA, RAW AND FILTERED, TO REFLECT CHANGES
       *SLOPPED TOGETHER FOR TIME PURPOSES RIGHT NOW. I AM SORRY.
       */
      this.reportsRaw.remediation.forEach((item,index) =>{
        if(data.record.u_id == item.u_id)
          this.reportsRaw.remediation[index] = data.record;
      });
      this.reports.remediation.mfdata.forEach((item,index) =>{
        if(data.record.u_id == item.u_id)
          this.reports.remediation.mfdata[index] = data.record;
      });
    });
//INITIAL LOAD, SETTIMEOUT TO MAKE SURE GRAPH TOKEN SET
    setTimeout(() => {
      this.graphService.getInboxCounts().subscribe(response =>{
        this.inboxNewMsg = response.unreadItemCount;
        this.graphService.getMail().subscribe(res=>{
          this.inboxNewMsgLoad = false;
        });
      });
    }, 2000);
//UPDATE INBOX COUNT, FETCH MAIL AND START INTERVAL
    setInterval(() => {
      this.graphService.getInboxCounts().subscribe(response =>{
        this.graphService.getMail().subscribe(res=>{});
        this.inboxNewMsg = response.unreadItemCount;
      });
    }, 120000);
  }

  tabClick($event: NgbTabChangeEvent,content) {
    if($event.nextId === 'inbox') {
      $event.preventDefault();
      this.openModal(content,'xlg',false,false);
    }
  }


  openModal(content,size,bulk,action) {
    if(bulk) {
      if(size !='xlg' && !action) {
        Object.keys(this.bulkActions).forEach(key =>{

          if(key !='records') {
            /*CHECK IF SELECTED RECORDS SHARE VALUE FOR THIS INPUT. IF YES, POPULATE THAT VALUE */
            let value = [];
            let recct = this.bulkActions.records.length;

            this.bulkActions.records.forEach(id =>{
              let rec = this.reportsRaw.remediation.filter(data => data.u_id === id.url_id)[0];
              if(key == 'resolved') key = 'resolution_dt';
              if(value.length === 0) value.push((rec[key] == 'true')? true : rec[key]);
              else {
                if(rec[key] == 'true' && value[0] === true) value.push(true);
                if(rec[key] != 'true' && value[0] == rec[key]) {
                  value.push((rec[key] == 'true')? true : rec[key]);
                }
              }
            });

            if(+value.length === +recct) {
              if(bulkdropdowns.includes(key)) {
                this.bulkActions[key] = (value[0] == null)? [] : (key =='assignee')? {name : value[0]}:[{name : value[0]}];
              }
              else if(key =='follow_up_dt') {
                let fud =  (value[0]!= null)? value[0].split("-") : null;
                this.bulkActions[key] = (fud != null)? {year : +fud[0], month: +fud[1], day:+fud[2]} : null;
              }
              else this.bulkActions[key] = value[0];
              if(key =='resolution_dt') this.bulkActions['resolved'] = (value[0] != null)? true : null;
            }
            else {
              this.bulkActions[key] = null;
            }
          }
        });
        this.modalService.open(content,{size : size,backdrop : 'static',windowClass: 'dark-modal'});
      }
      else {

        if(!action) {
          this.bulkEmailForm = {
            template_id: [],
            entity: [],
            from: [],
            to: [],
            cc: [],
            bcc: [],
            subject: null,
            body: null,
            next_status: null,
            infraction_header_id: []
          };
          this.buildBulkEmail(this.bulkActions.records);
        }
        else {
          this.addContactsToEmail = {country:null,contactType:action,contacts:[]};
        }
        this.activeM = this.modalService.open(content,{size : size,backdrop : 'static'});
      }
    }
    else {
      if(action == 'contact'){
        this.activeM = this.modalService.open(content,{size: size, backdrop : 'static'});
      }else{
        this.activeM = this.modalService.open(content,{size: size, backdrop : 'static', windowClass:'dark-modal'});
      }
    }

  }


  openRecord(record) {
    this.transferService.sendData(record);
  }
  /*MODAL TRIGGERS IF THIS IS A DATE CHANGE TO THE REMEDIATION LOG. NOT THE INITIAL FETCH */
  getRemediationData(modal) {

    if(modal) {
      this.modalService.dismissAll();
      this.loading = true;
      this.remediationDates.to_date.friendly = this.makeFriendlyDate(this.remediationDates.to_date.picker);
      this.remediationDates.from_date.friendly = this.makeFriendlyDate(this.remediationDates.from_date.picker);
      this.bulkActions.records = [];
    }
    let dataObj = {action : 'services/getremediationlog',
      vals : {'to_date' : this.remediationDates.to_date.friendly, 'from_date' : this.remediationDates.from_date.friendly, getCache: (sessionStorage.getItem('dropdowns') === null)?true:false,
        'infraction_types' : []
      }};


    if(this.advSearchForm.infraction_types != null) {
      this.advSearchForm.infraction_types.forEach(inf =>{
        dataObj.vals.infraction_types.push(inf.id);
      });
    }

    this.restService.postFunction(dataObj).subscribe(data =>{
      if(sessionStorage.getItem('dropdowns') === null) {
        var sessionCache =  {};
        sessionCache['assignees'] = data.cache.assignees;
        sessionCache['distributors'] = data.cache.distributors;
        sessionCache['countries'] = data.cache.countries;
        sessionCache['infraction_types'] = data.cache.infraction_types;

        //Nuskin
        if(JSON.parse(sessionStorage.currentUser).clients.includes('96764') || JSON.parse(sessionStorage.currentUser).clients.includes('2683')){
          sessionCache['suspended_status'] = [{name : 'Active'},{name :'Suspended'}, {name:'Terminated'}, {name:'Education Warning'}, {name:'Withholding'}];
        }
        else if(JSON.parse(sessionStorage.currentUser).clients.includes('99301') || JSON.parse(sessionStorage.currentUser).clients.includes('2683')) {
          sessionCache['suspended_status'] = [{name : 'Active'}, {name :'Probation'}, {name :'Suspended'}, {name:'Terminated'}];
        }
        else{
          sessionCache['suspended_status'] = [{name : 'Active'},{name :'Suspended'}, {name:'Terminated'}];
        }

        if(JSON.parse(sessionStorage.currentUser).clients.includes('99301')){
          sessionCache['region'] = [{name: 'West'},{name: 'Central'},{name: 'South'},{name: 'Southeast'},{name: 'Midwest'},{name: 'Northeast'}];
        }

        //usana
        if(JSON.parse(sessionStorage.currentUser).clients.includes('96719') || JSON.parse(sessionStorage.currentUser).clients.includes('97816')) {
          sessionCache['notice_status'] = [{name : 'Low'},{name :'Medium'}, {name:'High'}];
        }
        //nuskin
        else if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
          sessionCache['notice_status'] = [{name: '1st Message'},{name: '10 Day Letter'},{name: 'Ready For CRC'},{name: 'Decision Letter'},{name: 'Closed Compliant'},{name: 'Closed Non-Compliant'},{name: 'Unidentified Pending'}];
        }
        //advocare
        else if(JSON.parse(sessionStorage.currentUser).clients.includes('99301')){
          sessionCache['notice_status'] = [
            {name: '1st Notice Education'},
            {name: '2nd Notice Violation'},
            {name: '3rd Notice Probation'},
            {name: '4th Notice 30 Day Suspension'},
            {name: '5th Notice 3 Month Suspension'},
            {name: '6th Notice Termination'},
            {name: 'No Email'},
            {name: 'RSM Reach Out'}];
        }
        else{
          sessionCache['notice_status'] = [{name : 'Warning'},{name :'1st Notice'}, {name:'2nd Notice'}, {name:'Final Notice'}, {name:'Suspended'}];
        }
        sessionStorage.setItem('dropdowns', JSON.stringify(sessionCache));
      }


      data.data.forEach(da =>{
        if(da.client_followup == 0){
          da.client_followup = false;
        }else{
          da.client_followup = true;
        }
        // if(da.url_owner_email && da.url_owner_email.length > 30){
        //   da.url_owner_email = da.url_owner_email.slice(0, 25) + '...';
        // }
      });
      this.reports.remediation.mfdata = data.data;
      this.reportsRaw.remediation = data.data;
      this.reports.remediation.count = data.data.length;
      this.reports.remediation.filters.dropdowns = this.tablesService.dropdownFilters(this.reports.remediation.mfdata);
      this.reports.remediation.resultPagination  = this.tablesService.pagination(this.reports.remediation.count);
      this.reports.remediation.resultsShown = (this.reports.remediation.count < this.reports.remediation.resultsShown)? this.reports.remediation.count : 10;
      this.dropdownData = (sessionStorage.getItem('dropdowns') != null)? JSON.parse(sessionStorage.getItem('dropdowns')) : {};
      this.dropdownData.violation_status = [{id: 'yes', name: 'Resolved'}, {id: 'no', name: 'In Remediation'}];
      this.loading = false;
    });
  }
  /*UPDATES THE REMEDIATION REPORT ON THE DASHBOARD WITH THE USERS NEW LAYOUT */
  updateReportLayout() {
    this.restService.postFunction({action:'auth/updatereporttemplate', vals : this.layoutForm.columns}).subscribe(response=>{
      this.userData.report_temp = response;
      sessionStorage.setItem('currentUser',JSON.stringify(this.userData));
      this.reports.remediation.columns = [];
      this.layoutForm.columns.forEach(item =>{
        this.reports.remediation.columns.push(Object.assign({},item));
      });
      this.modalService.dismissAll();
    });
  }

  adjustBulkForm(url_id,header_id,suspended_owner_id,url_owner_email, assignee) {
    let el = this.bulkActions.records.find(itm => itm.url_id===url_id);
    if(el){
      this.bulkActions.records.splice(this. bulkActions.records.indexOf(el),1);
    }
    else{
      this.bulkActions.records.push({url_id :url_id, infraction_header_id: header_id, suspended_owner_id : suspended_owner_id, suspended_owner_email : url_owner_email, assignee: assignee});
    }
  }

  checkedLookup(url_id) {
    let chk = this.bulkActions.records.find(item => item.url_id === url_id);
    if(chk) return "rowhighlight";
  }

  /*BUILD THE BULK EMAIL TEMPLATE/FORM */
  buildBulkEmail(records) {
    this.emailSendLoad = true;
    this.bulkUrlEmailOwners.emails = [];
    this.bulkEmailCC = [this.userData.email];

    records.forEach(key =>{
      key.suspended_owner_email = key.suspended_owner_email.trim();
      if(key.suspended_owner_email != null && key.suspended_owner_email != '') {
        if (this.bulkUrlEmailOwners.emails.indexOf(key.suspended_owner_email) === -1 && key.suspended_owner_email.trim != '') {
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(String(key.suspended_owner_email).toLowerCase())) {
            this.bulkUrlEmailOwners.emails.push(key.suspended_owner_email);
          }
        }
      }
      if(key.suspended_owner_email != null && key.assignee != '' && key.assignee != null){
        if(this.bulkEmailCC.indexOf(key.assignee) === -1 && key.assignee.trim != ''){
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if( re.test(String(key.assignee).toLowerCase()) ){
            this.bulkEmailCC.push(key.assignee);
          }
        }
      }
    });

    let dataObj = {action:'services/buildbulkemail', vals:{records:records}};
    this.restService.postFunction(dataObj).subscribe(response =>{
      if(JSON.parse(sessionStorage.currentUser).client_email != 'dms@integrishield.com'){
        response.email_template.to = this.bulkUrlEmailOwners.emails.join();
      }else{
        response.email_template.to = "";
      }
      if(JSON.parse(sessionStorage.currentUser).client_email == '4life@integrishield.com'){
        response.email_template.cc = this.bulkEmailCC.join();
      }

      if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(JSON.parse(sessionStorage.currentUser).email).toLowerCase()) ){
          response.email_template.cc = JSON.parse(sessionStorage.currentUser).email;
        }
      }

      this.buildContacts(response.email_details.address_book);
      const recips = ['bcc'];
      Object.keys(this.bulkEmailForm).forEach(key =>{
        if(recips.indexOf(key) == -1) {
          this.bulkEmailForm[key] = response.email_template[key];
        }
        if(key =='template_id') {
          this.bulkEmailForm[key] = response.email_details.templates.filter(data => data.ts_id == response.email_template.template_id);
        }
      });

      Object.keys(this.dropdownSettings).forEach(key =>{
        if(key =='textField' || key == 'idField') {
          this.bulkEmailFormDropdowns.templates.settings['textField'] = 'ts_name';
          this.bulkEmailFormDropdowns.templates.settings['idField'] = 'ts_id';
          this.bulkEmailFormDropdowns.entities.settings['textField'] = 'cl';
          this.bulkEmailFormDropdowns.entities.settings['idField'] = 'entity_name';
          this.bulkEmailFormDropdowns.templates.data = response.email_details.templates;
          this.bulkEmailFormDropdowns.entities.data = response.email_details.entities;
        }
        else {
          this.bulkEmailFormDropdowns.templates.settings[key] = this.dropdownSettingsAlt[key];
          this.bulkEmailFormDropdowns.entities.settings[key] = this.dropdownSettingsAlt[key];
        }
      });

      this.emailSendLoad = false;
      this.bulkActions = {records : [], assignee:null, url_owner_country:null, notice_status:null, is_suspended:null, follow_up_dt:null, distributor_id:null, url_owner_email:null, false_positive:false, customer_review:false,resolved:false};
    });

  }

  processBulkAction(form) {
    let dataObj = {action : 'services/updateinfractions',vals : {}};
    let newVals = {};
    /*ADJUST VALUES FOR RECORD UPDATE*/
    Object.keys(this.bulkActions).forEach(key =>{
      if(key !='records') {
        if(bulkdropdowns.includes(key)) {
          if(key =='assignee') {
            if(typeof this.bulkActions[key] == 'object') {
              if(this.bulkActions[key] == null) newVals[key] = null;
              else if(typeof this.bulkActions[key]['name'] !='undefined') newVals[key] = this.bulkActions[key]['name'];
            }
            else {
              newVals[key] = (this.bulkActions[key] != "undefined")? this.bulkActions[key] : null;
            }
          }
          else {
            if(this.bulkActions[key] == null || this.bulkActions[key].length == 0) newVals[key] = "null";
            else {
              if(typeof this.bulkActions[key][0] =='string') newVals[key] = this.bulkActions[key][0];
              else newVals[key] = this.bulkActions[key][0].name;
            }
          }
        }
        else {
          if(bulkchk.includes(key)) {
            newVals[key] = (this.bulkActions[key] === true)?"1" : "0";
          }
          else {
            if(key =='follow_up_dt') newVals[key] = (this.bulkActions[key] != null)? this.bulkActions[key] : null;
            else {
              if(this.bulkActions[key] != null)
                newVals[key] = (typeof this.bulkActions[key] =='undefined' ||!this.bulkActions[key].length)? "null" : this.bulkActions[key];
              else newVals[key] = null;
            }
          }
        }
      }
      else {
        newVals[key] = this.bulkActions[key];
      }
    });

    Object.keys(newVals['records']).forEach(key =>{
      newVals['records'][key]['suspended_owner_email'] = newVals['url_owner_email'];
      newVals['records'][key]['suspended_status'] = newVals['is_suspended'];
      newVals['records'][key]['assignee'] = newVals['assignee'];
      newVals['records'][key]['social_one'] = newVals['social_one'];
      newVals['records'][key]['social_two'] = newVals['social_two'];
      newVals['records'][key]['social_three'] = newVals['social_three'];
      newVals['records'][key]['phone_one'] = newVals['phone_one'];
      newVals['records'][key]['phone_two'] = newVals['phone_two'];
    });



    Object.keys(newVals).forEach(key =>{
      if(newVals[key] === true) dataObj.vals[key] = '1';
      else if(newVals[key] === false) dataObj.vals[key] = '0';
      else dataObj.vals[key] = newVals[key];
      if(key =='follow_up_dt') {
        if(newVals[key] !=null) dataObj.vals[key] = this.makeFriendlyDate(newVals[key]);
        else dataObj.vals[key] = "null";
      }

    });

    let dist = JSON.parse(sessionStorage.dropdowns).distributors;

    // for (var i=0; i < dist.length; i++) {
    //   if (dist[i].name === dataObj.vals['distributor_id']) {
    //     dataObj.vals['compliance_distributor_id'] = dist[i].id
    //   }
    // }


    dataObj.vals['owner_country'] = dataObj.vals['url_owner_country'];
    delete dataObj.vals['url_owner_country'];

    dataObj.vals['owner_email'] = dataObj.vals['url_owner_email'];
    delete dataObj.vals['url_owner_email'];

    dataObj.vals['suspended_status'] = dataObj.vals['is_suspended'];
    delete dataObj.vals['is_suspended'];


    this.restService.postFunction(dataObj).subscribe(response =>{
      if(response.records) {
        this.bulkActions['success'] = true;
        this.bulkActions['msg']     = this.bulkActions.records.length+' records updated successfully.';
        /*UPDATE BOTH SETS OF DATA, RAW AND FILTERED, TO REFLECT CHANGES */
        this.reportsRaw.remediation.forEach((item,index) =>{
          Object.keys(response.records).forEach(key =>{
            if(response.records[key].u_id === item.u_id) {
              this.reportsRaw.remediation[index] = response.records[key];
            }
          });
        });
        this.reports.remediation.mfdata.forEach((item,index) =>{
          Object.keys(response.records).forEach(key =>{
            if(response.records[key].u_id === item.u_id) {
              this.reports.remediation.mfdata[index] = response.records[key];
            }
          });
        });
        if(!JSON.parse(sessionStorage.currentUser).clients.includes('8715')){
          setTimeout(() =>{
            this.modalService.dismissAll();
            this.bulkActions = {records : [], assignee:null, url_owner_country:null, notice_status:null, is_suspended:null, follow_up_dt:null, distributor_id:null, url_owner_email:null, false_positive:false, customer_review:false, resolved:false, note: null, social_one: null, social_two: null, social_three: null, phone_one: null, phone_two: null};
          },2000);
        }
      }
    });
  }
  /*TOGGLE FOLLOWUP ON RECORD */
  followupRecord(e,record) {
    this.restService.postFunction({action:'services/setfollowup', vals:{id : record.infraction_header_id, followup:e}}).subscribe(response =>{

    });
  }

  submitUrlInfraction() {

    this.modalService.dismissAll();

    let dataObj = {
      action: 'services/submitNewUrlInfraction',
      vals: {
        url: '',
        domains: '',
        assignee: '',
        infractions: '',
        owner_country: '',
        url_owner: '',
        distributor_id: '',
        follow_up_dt: '',
        social_one: '',
        discovery_dt: this.makeFriendlyDate(this.urlFormDates.url_discovery_date.picker)
      }
    };

    if(this.showFollowUpDate){
      dataObj.vals.url = this.makeFriendlyDate(this.urlFormDates.url_follow_date.picker);
    }

    if(this.urlInfractionForm.url != null) {
      dataObj.vals.url = this.urlInfractionForm.url;
    }

    if(this.urlInfractionForm.assignee != null) {
      dataObj.vals.assignee = this.urlInfractionForm.assignee;
    }

    if(this.urlInfractionForm.social_one != null) {
      dataObj.vals.social_one = this.urlInfractionForm.social_one;
    }

    if(this.urlInfractionForm.owner_country != null) {
      dataObj.vals.owner_country = this.urlInfractionForm.owner_country;
    }

    if(this.urlInfractionForm.url_owner != null) {
      dataObj.vals.url_owner = this.urlInfractionForm.url_owner;
    }

    if(this.urlInfractionForm.distributor_id != null) {
      dataObj.vals.distributor_id = this.urlInfractionForm.distributor_id;
    }

    if(this.urlInfractionForm.domains != null) {
      dataObj.vals.domains = this.urlInfractionForm.domains[0].id;
    }


    if(this.urlInfractionForm.infractions != null) {
      dataObj.vals.infractions = this.urlInfractionForm.infractions[0].id;
    }


    this.restService.postFunction(dataObj).subscribe(response =>{
      location.reload();

    });

  }

  processAdvancedSearch() {



    this.modalService.dismissAll();
    this.loading = true;

    this.remediationDates.to_date.friendly = this.makeFriendlyDate(this.advancedSearchDates.adv_to_date.picker);
    this.remediationDates.from_date.friendly = this.makeFriendlyDate(this.advancedSearchDates.adv_from_date.picker);

    let dataObj = {action: 'services/getremediationlog',
      vals:{adv: true, infraction_types: [], assignees: [], notice_status: [], suspended_status: [], clients: [], domains: [], violation_status: [], distributor_ids: '', url_owner_email: '', url_id: '', url: '', url_owner_country: [],
        from_date: this.makeFriendlyDate(this.advancedSearchDates.adv_from_date.picker),
        to_date: this.makeFriendlyDate(this.advancedSearchDates.adv_to_date.picker)}
    };



    if(this.advSearchForm.distributor_ids != null) {
      dataObj.vals.distributor_ids = this.advSearchForm.distributor_ids;
    }

    // if(this.advSearchForm.distributors != null) {
    //   this.advSearchForm.distributors.forEach(inf =>{
    //     dataObj.vals.distributors.push(inf.id);
    //   });
    // }

    if(this.advSearchForm.url_owner_email != null) {
      dataObj.vals.url_owner_email = this.advSearchForm.url_owner_email;
    }

    if(this.advSearchForm.url_owner_country != null) {
      dataObj.vals.url_owner_country = this.advSearchForm.url_owner_country;
    }

    if(this.advSearchForm.url_id != null) {
      dataObj.vals.url_id = this.advSearchForm.url_id;
    }

    if(this.advSearchForm.url != null) {
      dataObj.vals.url = this.advSearchForm.url;
    }

    if(this.advSearchForm.infraction_types != null) {
      this.advSearchForm.infraction_types.forEach(inf =>{
        dataObj.vals.infraction_types.push(inf.id);
      });
    }

    if(this.advSearchForm.infraction_types != null) {
      this.advSearchForm.infraction_types.forEach(inf =>{
        dataObj.vals.infraction_types.push(inf.id);
      });
    }

    if(this.advSearchForm.assignees != null) {
      this.advSearchForm.assignees.forEach(inf =>{
        if(inf != null){
          dataObj.vals.assignees.push(inf);
        }
      });
    }

    if(this.advSearchForm.notice_status != null) {
      this.advSearchForm.notice_status.forEach(inf =>{
        if(inf != null){
          dataObj.vals.notice_status.push(inf);
        }
      });
    }

    if(this.advSearchForm.suspended_status != null) {
      this.advSearchForm.suspended_status.forEach(inf =>{
        if(inf != null){
          dataObj.vals.suspended_status.push(inf);
        }
      });
    }

    if(this.advSearchForm.violation_status != null) {
      this.advSearchForm.violation_status.forEach(inf =>{
        if(inf != null){
          dataObj.vals.violation_status.push(inf.id);
        }
      });
    }

    if(this.advSearchForm.clients != null) {
      this.advSearchForm.clients.forEach(inf =>{
        if(inf != null){
          dataObj.vals.clients.push(inf);
        }
      });
    }

    if(this.advSearchForm.domains != null) {
      this.advSearchForm.domains.forEach(inf =>{
        if(inf != null){
          dataObj.vals.domains.push(inf.id);
        }
      });
    }

    this.restService.postFunction(dataObj).subscribe(response =>{
      this.reports.remediation.mfdata = response.data;
      response.data.forEach(val =>{
        if(val.client_followup == "0"){
          val.client_followup = false;
        }else{
          val.client_followup = true;
        }
        // if(val.url_owner_email && val.url_owner_email.length > 30){
        //   val.url_owner_email = val.url_owner_email.slice(0, 25) + '...';
        // }
      });
      this.reportsRaw.remediation = response.data;
      this.reports.remediation.count = response.data.length;
      this.reports.remediation.filters.dropdowns = this.tablesService.dropdownFilters(this.reports.remediation.mfdata);
      this.reports.remediation.resultPagination  = this.tablesService.pagination(this.reports.remediation.count);
      this.reports.remediation.resultsShown = (this.reports.remediation.count < this.reports.remediation.resultsShown)? this.reports.remediation.count : 10;
      this.loading = false;
    });

  }

  addDistributor(){


    let dataObj = {action: 'services/addDistributors',
      vals: {
        distributor_name: this.distributorForm.distributor_name,
        client_distributor_id: this.distributorForm.client_distributor_id
      }
    };

    this.restService.postFunction(dataObj).subscribe(data =>{
      this.distributorForm.distributor_name = '';
      this.distributorForm.client_distributor_id = '';
      var sessionCache =  {};
      sessionCache['assignees'] = data.cache.assignees;
      sessionCache['distributors'] = data.cache.distributors;
      sessionCache['countries'] = data.cache.countries;
      sessionCache['infraction_types'] = data.cache.infraction_types;
      if(JSON.parse(sessionStorage.currentUser).clients.includes('99301')) {
        sessionCache['suspended_status'] = [{name : 'Active'}, {name :'Probation'}, {name :'Suspended'}, {name:'Terminated'}];
      }
      else{
        sessionCache['suspended_status'] = [{name : 'Active'},{name :'Suspended'}, {name:'Terminated'}];
      }
      //usana
      if(JSON.parse(sessionStorage.currentUser).clients.includes('96719') || JSON.parse(sessionStorage.currentUser).clients.includes('97816')) {
        sessionCache['notice_status'] = [{name : 'Low'},{name :'Medium'}, {name:'High'}];
      }
      //nuskin
      else if(JSON.parse(sessionStorage.currentUser).clients.includes('96764')){
        sessionCache['notice_status'] = [{name: '1st Message'},{name: '10 Day Letter'},{name: 'Ready For CRC'},{name: 'Decision Letter'},{name: 'Closed Compliant'},{name: 'Closed Non-Compliant'},{name: 'Unidentified Pending'}];
      }
      else{
        sessionCache['notice_status'] = [{name : 'Warning'},{name :'1st Notice'}, {name:'2nd Notice'}, {name:'Final Notice'}, {name:'Suspended'}];
      }
      sessionStorage.setItem('dropdowns', JSON.stringify(sessionCache));
      setTimeout(() =>{ this.activeM.close();},1500);
    });
  }


  downloadLogReportBak() {
    /*CHANGE EXPORT TO INCLUDE TABLE FILTER RULES. JUST EXPORT UID */
    let urls = [];
    this.reports.remediation.mfdata.forEach(record =>{
      urls.push(record.u_id);
    });
    let dataObj = {action: 'services/getremediationlogexport',
      vals:{start:this.remediationDates.from_date.friendly,
        end:this.remediationDates.to_date.friendly,
        urls : urls}
    };
    this.restService.postFunction(dataObj).subscribe(response =>{
      var ws = XLSX.utils.json_to_sheet(response.data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, response.title);
      XLSX.writeFile(wb, response.filename);
    });
  }

  downloadLogReport() {
    /*CHANGE EXPORT TO INCLUDE TABLE FILTER RULES. JUST EXPORT UID */
    let urls = [];
    this.reports.remediation.mfdata.forEach(record =>{
      urls.push(record.u_id);
    });
    let dataObj = {action: 'services/getRemediationLogExportAlt',
      vals:{start:this.remediationDates.from_date.friendly,
        end:this.remediationDates.to_date.friendly,
        urls : urls}
    };
    this.restService.postFunction(dataObj).subscribe(response =>{
      if(window.location.hostname == 'localhost'){
        window.open('https://petrovic-portal-api.integrishield.com/reports/remediationLogExport.php?id=' + response.lastInsertId, "_blank");
      }else{
        window.open('https://portal-api.integrishield.com/reports/remediationLogExport.php?id=' + response.lastInsertId, "_blank");
      }
    });
  }

  /* FILTER TABLES ON DASHBOARD */
  filterReport(report,tooltip) {
    if(tooltip) tooltip.close();
    var rawData = Object.assign([],this.reportsRaw[report]);
    var results = [];
    var reset = true;
    Object.keys(this.reports[report].filters).forEach(fil =>{
      if(fil !='dropdowns') {
        if(this.reports[report].filters[fil].length > 0) {
          reset = false;
          if(results.length) {
            if(this.reports[report].filters[fil] == 'Empty'){
              results = results.filter(data => data[fil] == null || data[fil].trim() == '');
            }else{
              results = results.filter(data => (data[fil] != null)? data[fil].toLowerCase().includes(this.reports[report].filters[fil].toLowerCase()): null);
            }
          }
          else {
            if(this.reports[report].filters[fil] == 'Empty'){
              results = rawData.filter(data => data[fil] == null || data[fil].trim() == '');
            }else{
              results = rawData.filter(data => (data[fil] != null)? data[fil].toLowerCase().includes(this.reports[report].filters[fil].toLowerCase()): null);
            }
          }
        }
        if(fil =='infraction_action_id') {
          if(this.reports[report].filters[fil]) {
            reset = false;
            if(results.length) {
              results = results.filter(data => data[fil] != null);
            }
            else {
              results = rawData.filter(data => data[fil] != null);
            }
          }
        }
      }
    });

    if(reset) results = rawData;
    if(results.length > 0) {
      this.reports[report].mfdata = results;
      this.reports.remediation.count = results.length;
      this.reports.remediation.resultPagination  = this.tablesService.pagination(this.reports.remediation.count);
      this.reports.remediation.filters.dropdowns = this.tablesService.dropdownFilters(results);
    }
  }

  /*BUILD OUT THE LIST OF CONTACTS. SPLIT INTO EMAILS, COUNTRIES, AND RAW DATA, WHICH IS EXACTLY THAT, UNTOUCHED */
  buildContacts(contacts) {
    this.addressBook.raw_data = contacts;
    this.addressBook.emails = [];

    contacts.forEach(record =>{
      let emailex = this.addressBook.emails.filter(data => data.email.toLowerCase().includes(record.email.toLowerCase()));
      if(!emailex.length) this.addressBook.emails.push({email:record.email});
    });
  }



  getContactGroups(event) {
    let data = {};

    data['company'] = this.sessionData.clients[0];

    var dataObj = {action : 'services/getGroups', vals: data};
    this.restService.postFunction(dataObj).subscribe(response =>{
      this.addressBook['groups'] = response.data;
    });

  }

  getContacts(action) {
    this.addContactsToEmail.contacts = [];
    let emails = this.addressBook.raw_data.filter(data => data.group_name.toLowerCase().indexOf(this.addContactsToEmail.group[0].group_name.toLowerCase()) > -1);
    emails.forEach(em =>{
      const match = this.addContactsToEmail.contacts.filter(data => data == em.email);
      if(!match.length)
        this.addContactsToEmail.contacts.push(em.email);
    });
  }

  addContacts() {
    const type = this.addContactsToEmail.contactType;
    let emails = [];
    if(typeof this.bulkEmailForm[type] =='object') {
      this.bulkEmailForm[type].forEach(em =>{
        emails.push(em);
      });
    }
    else {
      emails = (!this.bulkEmailForm[type].length) ? [] : this.bulkEmailForm[type].split(',');
    }
    this.bulkEmailForm[type] = [];

    this.addContactsToEmail.contacts.forEach(c =>{
      let match = emails.filter(em => em == c);
      if(!match.length) emails.push(c);
    });
    this.bulkEmailForm[type] = emails;
    this.addContactsToEmail = {country:null,contactType:null,contacts:[]};
    this.activeM.close();
  }
  /*FETCHES NEW EMAIL TEMPLATE BASED ON USER INPUT */

  getTemplate(ev) {
    let data = {};

    data['ts_id'] = this.bulkEmailForm.template_id[0].ts_id;
    data['ts_name'] = this.bulkEmailForm.template_id[0].ts_name;
    data['entity_name'] = (typeof this.bulkEmailForm.entity == 'undefined')? null : this.bulkEmailForm.entity[0].entity_name;
    data['infraction_header_id'] = this.bulkEmailForm.infraction_header_id[0];
    data['infraction_header_ids'] = this.bulkEmailForm.infraction_header_id;

    var dataObj = {action : 'services/retrievetemplate',vals: data};

    this.restService.postFunction(dataObj).subscribe(response =>{
      Object.keys(this.bulkEmailForm).forEach(key =>{
        const ignore = ['infraction_header_id','entity','success','success_msg', 'to', 'cc', 'bcc'];
        if(ignore.indexOf(key) == -1) {
          if(key =='template_id') {
            this.bulkEmailFormDropdowns.templates.data.forEach(tmp =>{

              if(+tmp.ts_id === response.template_id) {
                this.bulkEmailForm[key].push(tmp);
              }
            });
          }
          else this.bulkEmailForm[key] = response[key];
        }
      });
    });
  }

  getAdvSearchDetails(event, field) {

    let data = {};

    data['field'] = field;
    data['selections'] = this.advSearchForm;

    var dataObj = {action : 'services/getAdvSearchDetails', vals: data};
    this.restService.postFunction(dataObj).subscribe(response =>{
      this.advSearchFields[field] = response.data;
    });

  }

  getInfractions() {
    var dataObj = {action : 'services/getInfractions'};
    this.restService.postFunction(dataObj).subscribe(response =>{
      this.urlField.infractions = response.data;
    });

  }

  getDistributors() {
    var dataObj = {action : 'services/getDistributorsBrowser'};
    this.restService.postFunction(dataObj).subscribe(response =>{
      this.urlField.distributor_id = response.data;
    });

  }

  advSearchSelect(event, msg) {
    if(event.id){
      this.advSearchSelected[msg].push(event.id)
    }else{
      this.advSearchSelected[msg].push(event)
    }
  }

  advSearchDeselect(event, msg) {
    var filtered = this.advSearchSelected[msg].filter(function(value, index, arr){
      if(event.id){
        return value != event.id;
      }else{
        return value != event;
      }
    });

    // this.advSearchSelected[msg] = filtered;
  }


  sendEmail() {
    this.emailSendLoad = true;
    this.bulkEmailForm['success'] = null;
    this.bulkEmailForm['error_msg'] = [];
    document.getElementById('etop').scrollIntoView();
    //this.emailSendLoad = true;

    let response = this.graphService.validateMessage(this.bulkEmailForm);

    if(typeof response['action'] == 'undefined') {
      this.emailSendLoad = false;
      this.bulkEmailForm.success   = false;
      this.bulkEmailForm.error_msg = response;
    }
    else {
      response['action'] = 'services/sendbulkemail';
      this.graphService.sendBulkMessage(response).subscribe(resp =>{
        if(!resp.emailSend){
          this.emailSendLoad = false;
          this.bulkEmailForm.success = false;
          this.bulkEmailForm.error_msg.push('Rejected by the email provider. Please contact your administrator.')
        }else{
          var urlIds = resp.url_ids.split(',');
          this.reportsRaw.remediation.forEach((item,index) =>{
            urlIds.forEach(url =>{
              if(url === item.u_id) {
                this.reportsRaw.remediation[index]['follow_up_dt'] = resp.follow_up_date.split(' ')[0];
                this.reportsRaw.remediation[index]['notice_status'] = resp.notice_status;
              }
            });
          });
          this.reports.remediation.mfdata.forEach((item,index) =>{
            urlIds.forEach(url =>{
              if(url === item.u_id) {
                this.reports.remediation.mfdata[index]['follow_up_dt'] = resp.follow_up_date.split(' ')[0];
                this.reports.remediation.mfdata[index]['notice_status'] = resp.notice_status;
              }
            });
          });
          this.emailSendLoad = false;
          this.bulkEmailForm.success = true;
          setTimeout(() =>{ this.modalService.dismissAll(); },2000);
        }

      });
    }
  }

  sendContactEmail() {

    if(this.emailForm.flag == 'contact'){
      let emailValid = {vals: {to: 'clientservices', subject: this.emailForm.subject, body: this.emailForm.body}};
      emailValid['action'] = 'graph/sendmessage'

      this.restService.postFunction(emailValid).subscribe(response =>{
        this.emailSendLoad = false;
        this.emailForm.success = true;
        setTimeout(() =>{
          this.modalService.dismissAll();
          this.emailForm = {from:this.sessionData.client_email, to:[], bcc:[], cc:[], subject:null ,body:null};
        },2000);
      });
    }else{
      this.emailForm['success'] = null;
      this.emailForm['error_msg'] = [];
      this.emailSendLoad = true;
      document.getElementById('etop').scrollIntoView();
      let email_valid = this.graphService.validateMessage(this.emailForm);

      if(typeof email_valid['action'] == 'undefined') {
        this.emailForm.success = false;
        this.emailForm.error_msg = email_valid;
        this.emailSendLoad = false;
      }
      else {
        email_valid['action'] = 'graph/sendmessage';
        this.restService.postFunction(email_valid).subscribe(response =>{
          this.emailSendLoad = false;
          this.emailForm.success = true;
          setTimeout(() =>{
            this.modalService.dismissAll();
            this.emailForm = {from:this.sessionData.client_email, to:[], bcc:[], cc:[], subject:null ,body:null};
          },2000);
        });
      }
    }




  }

  onContextMenu(event: MouseEvent, url_id, url) {
    event.preventDefault();
    let x = event.clientX - 253;
    let y = event.clientY - 240;
    this.contextMenuPosition.x = x + 'px';
    this.contextMenuPosition.y = y + 'px';
    this.contextMenu.menuData = url_id;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();

    sessionStorage.setItem('contactUrlID', url_id);
    sessionStorage.setItem('contactUrl', url);
    this.emailForm.subject = url;
    this.emailForm.flag = 'contact'

    return false;

  }



  makeFriendlyDate(date) {
    var returnDt = null;
    var day   = (date.day.toString().length < 2)? '0'+date.day : date.day;
    var month = (date.month.toString().length < 2)? '0'+date.month : date.month;
    returnDt = date.year+'-'+month+'-'+day;
    return returnDt;
  }

  refresh(){
    location.reload();
  }

}
